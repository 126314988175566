import {
  ChangeEvent, MouseEvent, memo, useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  TextField,
  Typography,
  ListItemText,
  Divider,
  ListItemButton
} from '@mui/material';
import { Add, ArrowDropDown } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectProperties, selectSelectedProperty, updateSelectedProperty } from '../../pages/property/propertySlice';
import { selectAccount } from '../../app/appSlice';
import { Property } from '../../models/property';
import './PropertyMenu.css';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';
import API from '../../api/api';
import { Document } from '../../models/common';

export const PropertyMenu = memo(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [stateProperties, setStateProperties] = useState<Property[]>();
  const [filteredProperties, setFilteredProperties] = useState<Property[]>();
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const properties = useAppSelector(selectProperties);
  const account = useAppSelector(selectAccount);
  const userIsAdmin = account?.groups?.includes('admin');

  useEffect(() => {
    const fetchUrls = async () => {
      const propertiesWithImgUrl = properties?.map(async property => {
        if (property.documents?.at(0)) {
          try {
            const response: Document = await API.get(`document/${property.documents?.at(0)}`);
            const url = response?.s3Key ? await generatePreSignedUrl(response.s3Key) : '';
            return {
              ...property,
              url
            };
          } catch {
            return property;
          }
        }
        return property;
      });

      if (propertiesWithImgUrl) {
        setStateProperties(await Promise.all(propertiesWithImgUrl));
        setFilteredProperties(await Promise.all(propertiesWithImgUrl));
      }
    };

    if (userIsAdmin) {
      fetchUrls();
    }
  }, [properties]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProperty = (propertyId: string) => {
    const selected = properties?.find(property => property.propertyId === propertyId);
    if (selected) {
      dispatch(updateSelectedProperty(selected));
      navigate('/');
    }
    handleClose();
  };

  const filter = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.toLowerCase();
    const filtered = stateProperties?.filter(property => property.propertyAddress.toLowerCase().includes(trimmedSearchTerm));
    setFilteredProperties(filtered);
  };

  if (!userIsAdmin) {
    return (
      <Typography
        variant="button"
        sx={{ maxWidth: 200, color: 'var(--primary)' }}
        noWrap
      >
        {selectedProperty?.propertyAddress?.split(',')[0]}
      </Typography>
    );
  }

  return (
    <div>
      <Button
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
      >
        <Typography variant="button" sx={{ maxWidth: 200 }} noWrap>
          {selectedProperty?.propertyAddress?.split(',')[0]}
        </Typography>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box className="property-menu">
          {properties && properties.length > 1 && (
            <TextField
              placeholder={t('property.propertyAddress')}
              className="search-property"
              label={t('common.search-properties')}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => filter(event.target.value)}
            />
          )}
          <Divider />
          <List className="property-list">
            {filteredProperties && filteredProperties.length > 0 /* @ts-ignore */
              ? filteredProperties.map(({ propertyId, propertyAddress, url }) => (
                <ListItem key={propertyId} disablePadding sx={{ alignItems: 'start' }}>
                  <ListItemButton className="property" onClick={() => openProperty(propertyId)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={propertyAddress}
                        src={url}
                        variant="rounded"
                        className="avatar"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      className={propertyAddress === selectedProperty?.propertyAddress ? 'selected' : ''}
                      primary={propertyAddress.split(',')[0]}
                      secondary={(
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {`${propertyAddress.split(',')[1]}, ${propertyAddress.split(',')[2]}`}
                        </Typography>
                      )}
                    />
                  </ListItemButton>
                </ListItem>
              )) : <Typography className="no-results">{t('common.noResults')}</Typography>}
          </List>
          <Divider />
          <Button
            className="add-new-property"
            startIcon={<Add />}
            onClick={() => navigate('/kiinteistö/uusi')}
          >
            {t('setup.createANew')}
          </Button>
        </Box>
      </Popover>
    </div>
  );
});
