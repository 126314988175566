import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import type { RootState } from '../../store';
import API from '../../api/api';
import { CreateEquipment, Equipment } from '../../models/equipment';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { updateError } from '../../app/appSlice';
import { fetchDocuments } from '../document/documentSlice';

export const fetchEquipments = createAsyncThunk<Equipment[], string | undefined, { rejectValue: Error }>(
  'equipment/fetchEquipments',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Equipment[] = await API.get(`equipments/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchEquipment = createAsyncThunk<Equipment, string, { rejectValue: Error }>(
  'equipment/fetchEquipment',
  async (equipmentId, { rejectWithValue }) => {
    try {
      const response: Equipment = await API.get(`equipment/${equipmentId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addEquipment = createAsyncThunk<Equipment | undefined, CreateEquipment, { rejectValue: Error }>(
  'equipment/addEquipment',
  async ({ equipment, propertyId }, { rejectWithValue, dispatch, getState }) => {
    try {
      let documents = null;
      if (propertyId) {
        documents = equipment.documents ? await generateDocumentIds(equipment.documents, propertyId) : null;
      }
      const state = getState();
      // @ts-ignore
      if (state?.permission?.linkUser) {
        // @ts-ignore
        const { linkUser } = state.permission;
        await API.post(
          `link/equipment/${linkUser.propertyId}/${linkUser.linkId}`,
          {
            ...equipment,
            propertyId: linkUser.propertyId,
            documents: equipment.documents ? (
              await generateDocumentIds(equipment.documents, linkUser.propertyId, linkUser.linkId)
            ) : null
          }
        );
        return undefined;
      }
      const response: Equipment = await API.post(
        'equipment',
        { ...equipment, propertyId, documents }
      );
      // TODO: This is heavy weight operation
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateEquipment = createAsyncThunk<Equipment, Equipment, { rejectValue: Error }>(
  'equipment/updateEquipment',
  async (equipment, { rejectWithValue }) => {
    try {
      const clone = (({ equipmentId, ...o }) => o)(equipment);
      const response: Equipment = await API.put(
        `equipment/${equipment.equipmentId}`,
        clone
      );
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteEquipment = createAsyncThunk<string, string, { rejectValue: Error }>(
  'equipment/deleteEquipment',
  async (equipmentId, { rejectWithValue }) => {
    try {
      await API.delete(`equipment/${equipmentId}`);
      return equipmentId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface EquipmentState {
  equipments: Equipment[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: EquipmentState = {
  equipments: [],
  loading: false
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEquipments.pending, state => {
        state.loading = true;
      })
      .addCase(fetchEquipments.fulfilled, (state, action) => {
        state.loading = false;
        state.equipments = action.payload;
      })
      .addCase(fetchEquipments.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(fetchEquipment.pending, state => {
        state.loading = true;
      })
      .addCase(fetchEquipment.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchEquipment.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(addEquipment.pending, state => {
        state.loading = true;
      })
      .addCase(addEquipment.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.equipments.push(action.payload);
        }
        toast.success(i18next.t('equipment.addedSuccessfully'));
      })
      .addCase(addEquipment.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(updateEquipment.fulfilled, (state, action) => {
        const updatedIndex = state.equipments.findIndex(({ equipmentId }) => equipmentId === action.payload.equipmentId);
        if (updatedIndex !== -1) {
          state.equipments[updatedIndex] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateEquipment.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(deleteEquipment.pending, state => {
        state.loading = true;
      })
      .addCase(deleteEquipment.fulfilled, (state, action) => {
        state.loading = false;
        const removeIndex = state.equipments.findIndex(({ equipmentId }) => equipmentId === action.payload);
        if (removeIndex !== -1) {
          state.equipments.splice(removeIndex, 1);
        }
      })
      .addCase(deleteEquipment.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      });
  }
});

export const selectEquipments = (state: RootState) => state.equipment.equipments;
export const selectEquipmentsLoading = (state: RootState) => state.equipment.loading;

export default equipmentSlice.reducer;
