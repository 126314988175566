import { DocumentFile } from './common';

export interface Property {
  propertyId: string;
  propertyCode: string;
  propertyAddress: string;
  siteSize: number;
  siteType: string;
  siteOwnership: string;
  plotLeasingCost?: number;
  buildingRight: number;
  buildingRightLeft?: number;
  buildingYear: number;
  deploymentTime: string;
  userId: string;
  documents: string[];
}

export interface UpdateProperty {
  property: Partial<Property>;
  image?: DocumentFile;
}

export enum PlotOwnership {
  OWN = 'own',
  RENTAL = 'rental'
}

export const propertyKeys = [
  'propertyCode',
  'propertyAddress',
  'propertyZipCode',
  'propertyCity',
  'siteSize',
  'siteType',
  'siteOwnership',
  'plotLeasingCost',
  'buildingRight',
  'buildingRightLeft',
  'buildingYear',
  'deploymentTime'
];
