import { Authenticator, Image, View } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import './LoginPage.css';

const customComponents = {
  Header: () => (
    <View textAlign="center" padding="20px 0 0">
      <Image
        alt="Kerasan logo"
        src="https://www.kerasan.fi/wp-content/uploads/2020/11/LOGOSquarespace.png"
      />
    </View>
  )
};

export const LoginPage = () => (
  <Box className="login-page">
    <Box className="authenticator-container">
      <Authenticator loginMechanisms={['email']} signUpAttributes={['name']} components={customComponents} />
    </Box>
  </Box>
);
