import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { dateToTableFormat, handleKeyDown } from '../../utils';
import { Renovation } from '../../models/renovation';
import { ChangeLog, Document, DocumentFile } from '../../models/common';
import {
  Image,
  ModalContainer,
  Pdf,
  Tabs,
  Table
} from '../../components';
import { updateRenovation } from './renovationSlice';
import { TabItem } from '../../models/components/tab';
import { selectSelectedProperty } from '../property/propertySlice';
import { selectDocuments } from '../document/documentSlice';

interface RenovationDetailsProps {
  selectedRenovation?: Renovation;
}

export const RenovationDetails = ({ selectedRenovation }: RenovationDetailsProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>('changeLog');
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [newNote, setNewNote] = useState<ChangeLog>({
    updatedAt: null,
    description: null
  });
  const [document, setDocument] = useState<DocumentFile>();

  const dispatch = useAppDispatch();
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const docs = useAppSelector(selectDocuments);

  const tabItems: TabItem[] = [
    { label: t('renovation.changeLog'), value: 'changeLog' },
    { label: t('common.documents'), value: 'documents' }
  ];

  const clearNote = () => setNewNote({ updatedAt: null, description: null });

  const handleSaveNote = async () => {
    if (selectedRenovation) {
      const changeLog = selectedRenovation.changeLog ? [...selectedRenovation.changeLog, newNote] : [newNote];
      await dispatch(updateRenovation({ renovation: { ...selectedRenovation, changeLog } }));
      clearNote();
    }
  };

  const handleSaveDocument = async () => {
    if (selectedRenovation && document) {
      await dispatch(updateRenovation({
        renovation: selectedRenovation,
        document,
        propertyId: selectedProperty?.propertyId
      }));
      setDocument(undefined);
    }
  };

  const handleFileSelection = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0 && selectedRenovation) {
      setDocument({
        file: fileList[0],
        description: null,
        date: new Date().toString()
      });
    }
  };

  const handeNoteChange = (description: string) => {
    setNewNote({
      updatedAt: new Date().toString(),
      description
    });
  };

  if (!selectedRenovation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Typography>{t('renovation.help')}</Typography>
      </Box>
    );
  }

  return (
    <>
      {selectedDocument && (
        <ModalContainer
          title={selectedDocument.name}
          open={!!selectedDocument}
          handleClose={() => setSelectedDocument(undefined)}
        >
          {selectedDocument.type === 'pdf'
            ? (
              <Pdf
                s3Key={selectedDocument.s3Key}
                name={selectedDocument.name}
                description={selectedDocument.description}
              />
            )
            : (
              <Image
                name={selectedDocument.name}
                description={selectedDocument.description}
                s3Key={selectedDocument.s3Key}
                date={selectedDocument.date}
              />
            )}
        </ModalContainer>
      )}
      <Box>
        <Tabs
          items={tabItems}
          selectedCategory={selectedTab}
          handleChange={value => setSelectedTab(value)}
        />
        {selectedTab === 'changeLog' && (
        <Table tableHeaders={[t('dateTime.date'), t('common.note'), '']}>
          <>
            {selectedRenovation.changeLog?.map(log => (
              <TableRow key={log.updatedAt}>
                <TableCell>{dateToTableFormat(log.updatedAt || '')}</TableCell>
                <TableCell>{log.description}</TableCell>
                <TableCell />
              </TableRow>
            ))}
            <TableRow>
              <TableCell>{dateToTableFormat(new Date().toISOString())}</TableCell>
              <TableCell>
                <TextField
                  placeholder={t('common.addNote')}
                  type="text"
                  variant="standard"
                  size="small"
                  value={newNote?.description || ''}
                  onChange={({ target }) => handeNoteChange(target.value)}
                  multiline
                />
              </TableCell>
              <TableCell>
                {newNote?.description ? (
                  <Box display="flex" flexDirection="row">
                    <IconButton title={t('common.saveNote')} onClick={handleSaveNote}>
                      <SaveOutlinedIcon />
                    </IconButton>
                    <IconButton title={t('common.removeNote')} onClick={clearNote}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Box>
                ) : null}
              </TableCell>
            </TableRow>
          </>
        </Table>
        )}
        {selectedTab === 'documents' && (selectedRenovation.documents || document) && (
        <Table tableHeaders={[t('common.name'), t('common.description'), '']}>
          <>
            {docs?.filter(doc => selectedRenovation.documents?.includes(doc.documentId)).map(doc => (
              <TableRow
                className="document-row"
                key={doc.documentId}
                onClick={() => setSelectedDocument(doc)}
                onKeyDown={e => handleKeyDown(e.code, () => setSelectedDocument(doc))}
                tabIndex={0}
                aria-label={t('button.open')}
              >
                <TableCell>{doc.name}</TableCell>
                <TableCell>{doc.description}</TableCell>
                <TableCell />
              </TableRow>
            ))}
            {document && (
            <TableRow>
              <TableCell>{document.file?.name.split('.').slice(0, -1).join('.')}</TableCell>
              <TableCell>
                <TextField
                  className="cell-form-field"
                  type="text"
                  value={document.description || ''}
                  onChange={({ target }) => setDocument({ ...document, description: target.value })}
                  placeholder={t('common.addDescription')}
                  variant="standard"
                  size="small"
                  maxRows={2}
                  multiline
                />
              </TableCell>
              <TableCell>
                <Box display="flex" flexDirection="row">
                  <Tooltip title={t('button.save')}>
                    <IconButton onClick={handleSaveDocument}>
                      <SaveOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('button.delete')}>
                    <IconButton onClick={() => setDocument(undefined)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
            )}
          </>
        </Table>
        )}
        {selectedTab === 'documents' && (
        <>
          <Button className="basic-button" onClick={() => inputRef.current?.click()}>
            {t('button.addFile')}
          </Button>
          <input
            ref={inputRef}
            type="file"
            accept="image/*,application/pdf, application/pdf, image/*"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileSelection(event.target.files)}
            hidden
          />
        </>
        )}
      </Box>
    </>
  );
};
