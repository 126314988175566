import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import fi from './locales/fi/translation.json';

const resources = {
  fi: { translation: fi }
};

i18next.use(initReactI18next).init({ resources, lng: 'fi' });

export default i18next;
