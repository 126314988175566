import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import i18next from 'i18next';
import {
  EquipmentForm,
  ModalContainer,
  Pdf,
  PrimaryContent,
  Table,
  Tabs,
  MobileTable,
  Image
} from '../../components';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { Equipment, EquipmentCategory } from '../../models/equipment';
import { fetchEquipments, selectEquipments, selectEquipmentsLoading } from './equipmentSlice';
import { selectSelectedProperty } from '../property/propertySlice';
import { Document } from '../../models/common';
import { TabItem } from '../../models/components/tab';
import { dateToTableFormat, handleKeyDown } from '../../utils';
import { selectDocuments, selectDocumentsLoading } from '../document/documentSlice';

export const EquipmentPage = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [addNew, setAddNew] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();

  const categoryItems: TabItem[] = ['all', ...Object.values(EquipmentCategory)]
    .map(category => ({ value: category, label: category === 'all' ? t('common.all') : t(`equipment.${category}`) }));

  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(selectIsMobile);
  const equipments = useAppSelector(selectEquipments);
  const equipmentsLoading = useAppSelector(selectEquipmentsLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const docs = useAppSelector(selectDocuments);

  useEffect(() => {
    dispatch(fetchEquipments(selectedProperty?.propertyId));
  }, [selectedProperty]);

  const filteredData = useMemo(() => (selectedCategory === 'all'
    ? equipments
    : equipments.filter(({ category }) => category === selectedCategory)
  ), [selectedCategory, equipments]);

  const getName = (name: string) => (i18next.exists(`equipmentName.${name}`) ? t(`equipmentName.${name}`) : name);

  const mobileData = filteredData.map(equipment => ({
    id: equipment.equipmentId,
    column1: dateToTableFormat(equipment.purchaseDate),
    column2: getName(equipment.name)
  }));

  return (
    <>
      <PrimaryContent
        title={t('common.devicesAndMachines')}
        subTitle={addNew ? t('equipment.new') : undefined}
        isLoading={equipmentsLoading || documentsLoading}
        button={isMobile
          ? {
            handleClick: () => setAddNew(!addNew),
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          } : {
            handleClick: () => setAddNew(!addNew),
            text: t(`button.${addNew ? 'cancel' : 'new'}`),
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          }}
      >
        <>
          {addNew && (
            <EquipmentForm handleClose={() => setAddNew(false)} />
          )}
          {!addNew && isMobile && (
            <>
              <TextField
                className="form-field"
                value={selectedCategory}
                onChange={({ target }) => setSelectedCategory(target.value)}
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
              >
                {categoryItems.map(({ value, label }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
              </TextField>
              {mobileData.length ? (
                <MobileTable
                  tableHeaders={[t('equipment.purchased'), t('equipment.name')]}
                  data={mobileData}
                  selected={selectedEquipment?.equipmentId || ''}
                  handleSelect={value => {
                    const equipment = filteredData.find(({ equipmentId }) => equipmentId === value);
                    setSelectedEquipment(equipment);
                  }}
                >
                  <Box>
                    {selectedEquipment && (
                    <dl>
                      <div>
                        <dt>{t('equipment.trademark')}:</dt>
                        <dd>{selectedEquipment.trademark}</dd>
                      </div>
                      <div>
                        <dt>{t('equipment.model')}:</dt>
                        <dd>{selectedEquipment.model}</dd>
                      </div>
                      <div>
                        <dt>{t('equipment.serialnumber')}:</dt>
                        <dd>{selectedEquipment.serialnumber}</dd>
                      </div>
                      <div>
                        <dt>{t('equipment.location')}:</dt>
                        <dd>{selectedEquipment.location}</dd>
                      </div>
                      <div>
                        <dt>{t('common.documents')}:</dt>
                        <dd>
                          {docs.filter(doc => selectedEquipment.documents?.includes(doc.documentId)).map(document => (
                            <Button key={document.documentId} className="underline-button" onClick={() => setSelectedDocument(document)}>
                              {document.name}
                            </Button>
                          ))}
                        </dd>
                      </div>
                    </dl>
                    )}
                  </Box>
                </MobileTable>
              ) : (
                <Typography>{t('equipment.noEquipments')}</Typography>
              )}
            </>
          )}
          {!addNew && !isMobile && (
            <>
              <Tabs
                items={categoryItems}
                selectedCategory={selectedCategory}
                handleChange={(value: string) => setSelectedCategory(value)}
              />
              {filteredData.length ? (
                <Table
                  tableHeaders={[
                    t('equipment.purchased'),
                    t('equipment.name'),
                    t('equipment.location'),
                    t('equipment.makeAndModel'), ''
                  ]}
                >
                  {filteredData.map(equipment => (
                    <TableRow key={equipment.equipmentId}>
                      <TableCell>{dateToTableFormat(equipment.purchaseDate)}</TableCell>
                      <TableCell>{getName(equipment.name)}</TableCell>
                      <TableCell>{equipment.location}</TableCell>
                      <TableCell>{equipment.trademark}<br />
                        {equipment.model}{equipment.serialnumber && `, ${equipment.serialnumber}`}
                      </TableCell>
                      <TableCell>
                        {equipment?.documents && (
                        <Button
                          className="underline-button"
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setSelectedEquipment(equipment);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          {t('common.documents')}
                        </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              ) : <Typography>{t('equipment.noEquipments')}</Typography>}
            </>
          )}
        </>
      </PrimaryContent>
      {selectedDocument && (
      <ModalContainer
        title={selectedDocument.name}
        open={!!selectedDocument}
        handleClose={() => setSelectedDocument(undefined)}
      >
        {selectedDocument.type === 'image' ? (
          <Image
            name={selectedDocument.name}
            description={selectedDocument.description}
            s3Key={selectedDocument.s3Key}
            date={selectedDocument.date}
          />
        ) : (
          <Pdf
            s3Key={selectedDocument.s3Key}
            name={selectedDocument.name}
            description={selectedDocument.description}
          />
        )}
      </ModalContainer>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box padding="5px">
          <Table tableHeaders={[t('common.name'), t('dateTime.date')]}>
            {docs.filter(doc => selectedEquipment?.documents?.includes(doc.documentId)).map(document => (
              <TableRow
                key={document.documentId}
                onClick={() => setSelectedDocument(document)}
                onKeyDown={e => handleKeyDown(e.code, () => setSelectedDocument(document))}
                tabIndex={0}
                aria-label={t('button.open')}
                className="document-row"
              >
                <TableCell>{document.name}</TableCell>
                <TableCell>{dateToTableFormat(document.date)}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
      </Popover>
    </>
  );
};
