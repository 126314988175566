import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import type { RootState } from '../../store';
import API from '../../api/api';
import { CreateMaintenance, Maintenance, UpdateMaintenance } from '../../models/maintenance';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { updateError } from '../../app/appSlice';
import { fetchDocuments } from '../document/documentSlice';

export const fetchMaintenances = createAsyncThunk<Maintenance[], string | undefined, { rejectValue: Error }>(
  'maintenance/fetchMaintenances',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Maintenance[] = await API.get(`maintenances/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchMaintenance = createAsyncThunk<Maintenance, string, { rejectValue: Error }>(
  'maintenance/fetchMaintenance',
  async (maintenanceId, { rejectWithValue }) => {
    try {
      const response: Maintenance = await API.get(`maintenance/${maintenanceId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addMaintenance = createAsyncThunk<Maintenance | undefined, CreateMaintenance, { rejectValue: Error }>(
  'maintenance/addMaintenance',
  async ({ maintenance, propertyId }, { rejectWithValue, dispatch, getState }) => {
    try {
      let documents = null;
      if (propertyId) {
        documents = maintenance.documents ? await generateDocumentIds(maintenance.documents, propertyId) : null;
      }
      const state = getState();
      // @ts-ignore
      if (state?.permission?.linkUser) {
        // @ts-ignore
        const { linkUser } = state.permission;
        await API.post(
          `link/maintenance/${linkUser.propertyId}/${linkUser.linkId}`,
          {
            ...maintenance,
            propertyId: linkUser.propertyId,
            documents: maintenance.documents ? (
              await generateDocumentIds(maintenance.documents, linkUser.propertyId, linkUser.linkId)
            ) : null
          }
        );
        return undefined;
      }
      const response: Maintenance = await API.post('maintenance', {
        ...maintenance,
        propertyId,
        documents
      });
      // TODO: This is heavy weight operation
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateMaintenance = createAsyncThunk<Maintenance, UpdateMaintenance, { rejectValue: Error }>(
  'maintenance/updateMaintenance',
  async ({
    newMaintenance, oldDocuments, maintenanceId, propertyId
  }, { rejectWithValue, dispatch }) => {
    try {
      let allDocuments: string[] = [];
      if (oldDocuments) {
        allDocuments = allDocuments.concat(oldDocuments);
      }
      if (newMaintenance.documents) {
        const newDocuments = await generateDocumentIds(newMaintenance.documents, propertyId);
        // @ts-ignore
        allDocuments = allDocuments.concat(newDocuments);
      }
      const response: Maintenance = await API.put(
        `maintenance/${maintenanceId}`,
        { ...newMaintenance, documents: allDocuments.length ? allDocuments : null }
      );
      // TODO: This is heavy weight operation
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteMaintenance = createAsyncThunk<string, string, { rejectValue: Error }>(
  'maintenance/deleteMaintenance',
  async (maintenanceId, { rejectWithValue }) => {
    try {
      await API.delete(`maintenance/${maintenanceId}`);
      return maintenanceId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface MaintenanceState {
  maintenances: Maintenance[] | undefined;
  loading: boolean;
  selectedMaintenance?: Maintenance | undefined,
  reminded: boolean;
}

// Define the initial state using that type
const initialState: MaintenanceState = {
  maintenances: [],
  loading: false,
  reminded: false
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    updateSelectedMaintenance: (state, action: PayloadAction<Maintenance | undefined>) => {
      state.selectedMaintenance = action.payload;
    },
    updateRemindedStatus: state => {
      state.reminded = true;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMaintenances.pending, state => {
        state.loading = true;
      })
      .addCase(fetchMaintenances.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenances = action.payload;
      })
      .addCase(fetchMaintenances.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(fetchMaintenance.pending, state => {
        state.loading = true;
      })
      .addCase(fetchMaintenance.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchMaintenance.rejected, (state, action) => {
        updateError(action.payload);
        state.loading = false;
      })
      .addCase(addMaintenance.pending, state => {
        state.loading = true;
      })
      .addCase(addMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenances = state.maintenances || [];
        if (action.payload) {
          state.maintenances.push(action.payload);
        }
        toast.success(i18next.t('maintenance.addedSuccessfully'));
      })
      .addCase(addMaintenance.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(updateMaintenance.pending, state => {
        state.loading = true;
      })
      .addCase(updateMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenances = state.maintenances || [];
        const updatedIndex = state.maintenances.findIndex(({ maintenanceId }) => maintenanceId === action.payload.maintenanceId);
        if (updatedIndex !== -1) {
          state.maintenances[updatedIndex] = action.payload;
        }
        toast.success(i18next.t('maintenance.updatedSuccessfully'));
      })
      .addCase(updateMaintenance.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(deleteMaintenance.pending, state => {
        state.loading = true;
      })
      .addCase(deleteMaintenance.fulfilled, (state, action) => {
        state.maintenances = state.maintenances || [];
        const removedIndex = state.maintenances.findIndex(({ maintenanceId }) => maintenanceId === action.payload);
        if (removedIndex !== -1) {
          state.maintenances.splice(removedIndex, 1);
        }
        state.loading = false;
      })
      .addCase(deleteMaintenance.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      });
  }
});

export const selectMaintenances = (state: RootState) => state.maintenance.maintenances;
export const selectMaintenancesLoading = (state: RootState) => state.maintenance.loading;
export const selectSelectedMaintenance = (state: RootState) => state.maintenance.selectedMaintenance;
export const selectReminded = (state: RootState) => state.maintenance.reminded;

export const { updateSelectedMaintenance, updateRemindedStatus } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
