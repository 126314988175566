import { StyleSheet, Font } from '@react-pdf/renderer';
// @ts-ignore
import font from '../../assets/fonts/RobotoCondensed-VariableFont_wght.ttf';

Font.register({
  family: 'Roboto Condensed',
  src: font,
  format: 'truetype'
});

export const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Roboto Condensed'
  },
  title: {
    marginVertical: 10
  },
  image: {
    width: '50%',
    marginHorizontal: 'auto',
    marginVertical: 30,
    borderRadius: 5
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid #e8ebe9',
    padding: 2,
    fontSize: 14
  },
  label: {
    width: '45%'
  },
  value: {
    width: '55%'
  },
  renovationContainer: {
    marginVertical: 30
  }
});
