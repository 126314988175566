import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import './ProfileMenu.css';
import { MoreVertOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogOut } from '../../hooks/useLogOut';

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logOut = useLogOut();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlined style={{ color: '#fff' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={() => navigate('/profiili')}>{t('common.profile')}</MenuItem>
        <MenuItem onClick={logOut}>{t('menu.logout')}</MenuItem>
      </Menu>
    </div>
  );
};
