import { ListObjectsCommand } from '@aws-sdk/client-s3';
import s3Client from '../clients/s3Client';
import { S3_BUCKET } from '../../config';

const listS3Objects = async (key: string) => {
  const params = {
    Bucket: S3_BUCKET,
    MaxKeys: 1000,
    Prefix: key,
    Delimiter: key
  };

  try {
    const command = new ListObjectsCommand(params);
    const data = await s3Client.send(command);
    const contents = data.Contents;
    const allKeys = contents?.map(content => content.Key);
    return allKeys;
  } catch (e) {
    return undefined;
  }
};

export default listS3Objects;
