import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertyMenu } from '../propertyMenu/PropertyMenu';
import './LogoContainer.css';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';

interface LogoContainerProp {
  showMenu: boolean;
}

export const LogoContainer = ({ showMenu } : LogoContainerProp) => {
  const { t } = useTranslation();
  const isMobile = useAppSelector(selectIsMobile);

  return (
    <Box className="logo-container">
      <Typography component="h1" variant={isMobile ? 'h6' : 'h5'} className="navigation-header">
        {t('mobileHouse')}
      </Typography>
      {showMenu && !isMobile && (<PropertyMenu />)}
    </Box>
  );
};
