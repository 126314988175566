import { Box, Typography } from '@mui/material';
import './Image.css';
import { useEffect, useState } from 'react';
import { dateToTableFormat } from '../../utils';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';
import { DownloadFileButton } from '../buttons/DownloadFileButton';

interface ImageProps {
  s3Key: string;
  name: string;
  description?: string;
  date: string;
}

export const Image = ({
  s3Key, name, description, date
}: ImageProps) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const fetchUrl = async () => {
      setUrl(await generatePreSignedUrl(s3Key));
    };

    fetchUrl();
  }, [s3Key]);

  if (!url) {
    return <Box />;
  }

  return (
    <Box className="image-container">
      <Box className="image">
        <img
          src={url}
          alt={name}
          crossOrigin="anonymous"
        />
      </Box>
      <Box className="description-container">
        <Typography mb="5px">{dateToTableFormat(date)}</Typography>
        <Typography>{description}</Typography>
      </Box>
      <DownloadFileButton url={url} name={name} />
    </Box>
  );
};
