import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { pdfjs } from 'react-pdf';
import { Authenticator } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer theme="light" position="top-center" autoClose={3000} />
        </BrowserRouter>
      </Provider>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
