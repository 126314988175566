import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmContainer,
  PrimaryContent,
  PropertyForm
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { updateProperty, deleteProperty, selectSelectedProperty } from './propertySlice';
import { dateToTableFormat } from '../../utils';
import { Property, PlotOwnership } from '../../models/property';
import './PropertyPage.css';
import { PropertyImage } from './PropertyImage';

export const PropertyPage = () => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const [propertyFormData, setPropertyFormData] = useState<Partial<Property>>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(selectIsMobile);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedProperty) {
      navigate('/kiinteistö/aloitus');
    }
    setPropertyFormData(selectedProperty);
  }, [selectedProperty]);

  const handleSave = () => {
    if (propertyFormData) {
      setEdit(false);
      dispatch(updateProperty({ property: propertyFormData }));
    }
  };

  return (
    <>
      <PrimaryContent
        title={t('common.property')}
        subTitle={edit ? t('property.edit') : undefined}
        button={isMobile
          ? {
            handleClick: () => setEdit(!edit),
            icon: edit ? <CloseOutlinedIcon /> : <EditOutlinedIcon />
          } : {
            handleClick: () => setEdit(!edit),
            text: t(`button.${edit ? 'cancel' : 'edit'}`),
            icon: edit ? <CloseOutlinedIcon /> : <EditOutlinedIcon />
          }}
      >
        <Box className="general-page-container">
          <Box>
            {edit ? (
              <PropertyForm
                propertyData={propertyFormData}
                handleChange={
                    (key: keyof Property, newValue: string | number | Date) => {
                      setPropertyFormData(prevState => ({ ...prevState, [key]: newValue }));
                    }
                  }
              />
            ) : (
              <>
                <PropertyImage selectedProperty={selectedProperty} />
                {selectedProperty && (
                  <Box className="details">
                    <Box>
                      <Typography>{t('property.propertyCode')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.propertyCode}</Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.propertyAddress')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.propertyAddress}</Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.siteSize')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.siteSize} m<sup>2</sup></Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.siteOwnership')}:</Typography>
                      <Typography component="p" variant="h6">{t(`property.${selectedProperty.siteOwnership}`)} {selectedProperty.siteOwnership === PlotOwnership.RENTAL && `(${t('property.plotLeasingCost')}: ${selectedProperty.plotLeasingCost}€ )`}</Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.siteType')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.siteType} </Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.buildingRight')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.buildingRight}  m<sup>2</sup> ( {t('property.remaining')}: {selectedProperty.buildingRightLeft}  m<sup>2</sup>)</Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.buildingYear')}:</Typography>
                      <Typography component="p" variant="h6">{selectedProperty.buildingYear}</Typography>
                    </Box>
                    <Box>
                      <Typography>{t('property.deploymentTime')}:</Typography>
                      <Typography component="p" variant="h6">
                        {selectedProperty.deploymentTime && dateToTableFormat(selectedProperty.deploymentTime)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
            {edit && (
              <Box className="property-form-buttons">
                <Button startIcon={<SaveOutlinedIcon />} className="basic-button" onClick={handleSave}>
                  {t('button.save')}
                </Button>
                <Button className="delete-button" onClick={() => setShowConfirm(true)}>{t('button.delete')}</Button>
              </Box>
            )}
          </Box>
        </Box>
      </PrimaryContent>
      {showConfirm && (
        <ConfirmContainer
          text={t('property.confirmDelete')}
          onCancel={() => setShowConfirm(false)}
          confirmButton={{
            text: t('button.delete'),
            onConfirm: () => dispatch(deleteProperty(propertyFormData?.propertyId!))
          }}
        />
      )}
    </>
  );
};
