import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import API from '../../api/api';

export const startOrder = createAsyncThunk('payment/startOrder', async (_payload, thunkAPI) => {
  try {
    const url: string = await API.post('payment');
    // TODO: Double check this. Is this safe way to do redirecting?
    window.location.assign(url);
    return null;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const validateSignature = createAsyncThunk('payment/validateSignature', async (_payload: any, thunkAPI) => {
  try {
    const valid: any = await API.post('payment/validateSignature', {
      _payload
    });
    return valid;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

// Define a type for the slice state
interface OrderState {
  loading: boolean;
  error: boolean;
  valid: any;
}

// Define the initial state using that type
const initialState: OrderState = {
  loading: false,
  error: false,
  valid: undefined
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(startOrder.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(startOrder.fulfilled, state => {
        state.loading = false;
      })
      .addCase(startOrder.rejected, state => {
        state.loading = false;
        state.error = true;
      })
      .addCase(validateSignature.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(validateSignature.fulfilled, (state, action) => {
        state.valid = action.payload.valid;
        state.loading = false;
      })
      .addCase(validateSignature.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const selectLoading = (state: RootState) => state.order.loading;
export const selectError = (state: RootState) => state.order.error;
export const selectValid = (state: RootState) => state.order.valid;

export default orderSlice.reducer;
