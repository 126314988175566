import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import type { RootState } from '../../store';
import API from '../../api/api';
import { CreateRenovation, Renovation, UpdateRenovation } from '../../models/renovation';
import { Error } from '../../models/common';
import { generateDocumentIds, parseError } from '../../utils';
import { updateError } from '../../app/appSlice';
import { fetchDocuments } from '../document/documentSlice';

export const fetchRenovations = createAsyncThunk<Renovation[], string | undefined, { rejectValue: Error }>(
  'renovation/fetchRenovations',
  async (propertyId, { rejectWithValue }) => {
    try {
      const response: Renovation[] = await API.get(`renovations/${propertyId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchRenovation = createAsyncThunk<Renovation, string, { rejectValue: Error }>(
  'renovation/fetchRenovation',
  async (renovationId, { rejectWithValue }) => {
    try {
      const response: Renovation = await API.get(`renovation/${renovationId}`);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const addRenovation = createAsyncThunk<Renovation | undefined, CreateRenovation, { rejectValue: Error }>(
  'renovation/addRenovation',
  async ({ renovation, propertyId }, { rejectWithValue, dispatch, getState }) => {
    try {
      let documents = null;
      if (propertyId) {
        documents = renovation.documents ? await generateDocumentIds(renovation.documents, propertyId) : null;
      }
      const state = getState();
      // @ts-ignore
      if (state?.permission?.linkUser) {
        // @ts-ignore
        const { linkUser } = state.permission;
        await API.post(
          `link/renovation/${linkUser.propertyId}/${linkUser.linkId}`,
          {
            ...renovation,
            propertyId: linkUser.propertyId,
            documents: renovation.documents ? (
              await generateDocumentIds(renovation.documents, linkUser.propertyId, linkUser.linkId)
            ) : null
          }
        );
        return undefined;
      }
      const response: Renovation = await API.post('renovation', { ...renovation, propertyId, documents });
      // TODO: This is heavy weight opertion
      dispatch(fetchDocuments(propertyId));
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateRenovation = createAsyncThunk<Renovation, UpdateRenovation, { rejectValue: Error }>(
  'renovation/updateRenovation',
  async ({ renovation, document, propertyId }, { rejectWithValue, dispatch }) => {
    try {
      if (document?.file && propertyId) {
        const documents = await generateDocumentIds([document], propertyId);
        const allDocuments = renovation.documents
          ? [...renovation.documents, ...documents] : documents;
        const renovationWithoutId = (({ renovationId, ...o }) => o)({ ...renovation, documents: allDocuments });
        const response: Renovation = await API.put(`renovation/${renovation.renovationId}`, renovationWithoutId);
        // TODO: This is heavy weight opertion
        dispatch(fetchDocuments(propertyId));
        return response;
      }
      const renovationWithoutId = (({ renovationId, ...o }) => o)(renovation);
      const response: Renovation = await API.put(`renovation/${renovation.renovationId}`, renovationWithoutId);
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const deleteRenovation = createAsyncThunk<string, string, { rejectValue: Error }>(
  'renovation/deleteRenovation',
  async (renovationId, { rejectWithValue }) => {
    try {
      await API.delete(`renovation/${renovationId}`);
      return renovationId;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

// Define a type for the slice state
interface RenovationState {
  renovations: Renovation[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: RenovationState = {
  renovations: [],
  loading: false
};

export const renovationSlice = createSlice({
  name: 'renovation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRenovations.pending, state => {
        state.loading = true;
      })
      .addCase(fetchRenovations.fulfilled, (state, action) => {
        state.loading = false;
        state.renovations = action.payload;
      })
      .addCase(fetchRenovations.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(fetchRenovation.pending, state => {
        state.loading = true;
      })
      .addCase(fetchRenovation.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fetchRenovation.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(addRenovation.pending, state => {
        state.loading = true;
      })
      .addCase(addRenovation.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.renovations.push(action.payload);
        }
        toast.success(i18next.t('renovation.addedSuccessfully'));
      })
      .addCase(addRenovation.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(updateRenovation.pending, state => {
        state.loading = true;
      })
      .addCase(updateRenovation.fulfilled, (state, action) => {
        const updatedIndex = state.renovations.findIndex(({ renovationId }) => renovationId === action.payload.renovationId);
        if (updatedIndex !== -1) {
          state.renovations[updatedIndex] = action.payload;
        }
        state.loading = false;
        toast.success(i18next.t('renovation.updatedSuccessfully'));
      })
      .addCase(updateRenovation.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      })
      .addCase(deleteRenovation.pending, state => {
        state.loading = true;
      })
      .addCase(deleteRenovation.fulfilled, (state, action) => {
        const removeIndex = state.renovations.findIndex(({ renovationId }) => renovationId === action.payload);
        if (removeIndex !== -1) {
          state.renovations.splice(removeIndex, 1);
        }
        state.loading = false;
      })
      .addCase(deleteRenovation.rejected, (state, action) => {
        state.loading = false;
        updateError(action.payload);
      });
  }
});

export const selectRenovations = (state: RootState) => state.renovation.renovations;
export const selectRenovationsLoading = (state: RootState) => state.renovation.loading;

export default renovationSlice.reducer;
