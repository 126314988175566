import { Box, Slide } from '@mui/material';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { TopNavBar } from '../navBar/TopNavBar';
import { SideNavBar } from '../navBar/SideNavBar';
import './PageSkeleton.css';

interface PageProps {
  children: JSX.Element | JSX.Element[];
}

export const PageSkeleton = ({ children }: PageProps) => {
  const isMobile = useAppSelector(selectIsMobile);

  const desktopSkeleton = (
    <Box className="page-container">
      <SideNavBar />
      <Box className="page-content">
        <Slide direction="right" in>
          <Box className="slide-container">
            {children}
          </Box>
        </Slide>
      </Box>
    </Box>
  );

  const mobileSkeleton = (
    <Box className="page-container">
      <TopNavBar />
      <Box className="page-content">
        {children}
      </Box>
    </Box>
  );

  return isMobile ? mobileSkeleton : desktopSkeleton;
};
