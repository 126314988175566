import {
  Table as MUITable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import './Table.css';

interface TableProps {
  tableHeaders: string[];
  children: JSX.Element | JSX.Element[] | string;
}

export const Table = ({ tableHeaders, children }: TableProps) => (
  <TableContainer className="table-container">
    <MUITable size="small">
      <TableHead>
        <TableRow>
          {tableHeaders.map(header => <TableCell key={header}>{header}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
    </MUITable>
  </TableContainer>
);
