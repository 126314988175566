import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Typography } from '@mui/material';
import './Loading.css';

interface LoadingProps {
  text?: string;
}

export const Loading = ({ text }: LoadingProps) => {
  const { t } = useTranslation();
  return (
    <Box className="loading-container">
      <Box className="linear-progress-container">
        {text && <Typography>{text ?? t('common.loading')}</Typography>}
        <LinearProgress className="linear-progress" color="secondary" />
      </Box>
    </Box>
  );
};
