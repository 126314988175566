import { v4 as uuidv4 } from 'uuid';
import putObject from './aws/api/putObject';
import { Document, DocumentFile, Error } from './models/common';
import API from './api/api';
import listS3Objects from './aws/api/listS3Objects';
import generatePreSignedUrl from './aws/api/generatePreSignedUrl';

export const dateToTableFormat = (date: string): string => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  return `${day}.${month}.${year}`;
};

export const dateToFormFormat = (date: string) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const parseError = (err: any): Error => {
  const error: Error = {
    message: err.message
  };

  if (err.response) {
    error.status = err.response.status;
    error.data = err.response.data;
  }

  return error;
};

export const downloadFile = async (url: string, name: string) => {
  const response = await fetch(url || '', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': 'attachment'
    }
  });
  const blob = await response.blob();
  const href = URL.createObjectURL(new Blob([blob], { type: 'image/*' }));
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
};

export const handleKeyDown = (code: string, callback: () => void) => {
  if (code === 'Enter' || code === 'Space') {
    callback();
  }
};

export const saveDocumentToS3 = async (key: string, document: DocumentFile) => {
  if (!document.file) {
    return undefined;
  }

  const result = await putObject(key, document.file);
  if (result?.$metadata.httpStatusCode === 200) {
    const newDocument = {
      name: document.file.name,
      s3Key: key,
      type: document.file.type.includes('pdf') ? 'pdf' : 'image',
      date: document.date,
      category: document.category ?? null,
      description: document.description
    };
    return newDocument;
  }
  return undefined;
};

export const generateDocumentIds = async (documents: DocumentFile[], propertyId: string, linkId = null) => {
  const documentIds = documents.map(async (document: DocumentFile) => {
    if (document.file) {
      const s3DocumentId = uuidv4();
      const s3Document = await saveDocumentToS3(`${propertyId}/${s3DocumentId}/${document.file.name}`, document);
      if (s3Document) {
        if (linkId) {
          const savedDocument: Document = await API.post(`link/document/${propertyId}/${linkId}`, { ...s3Document, propertyId });
          return savedDocument.documentId;
        }
        const savedDocument: Document = await API.post('document', { ...s3Document, propertyId });
        return savedDocument.documentId;
      }
    }
    return undefined;
  });
  const docIds = await Promise.all(documentIds);
  return docIds?.filter(id => id);
};

export const isFormValid = (formData: { [key: string]: any }, requiredFields: Array<string>) => (
  !requiredFields.some(field => !formData[field])
);

export const getBackgroundUrl = async (propertyId: string) => {
  const objs = await listS3Objects(`${propertyId}/background`);
  if (objs?.length) {
    const url = await generatePreSignedUrl(objs[0] ?? '');
    return url;
  }
  return undefined;
};
