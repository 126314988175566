import {
  USER_POOL_ID,
  USER_POOL_CLIENT_ID,
  S3_BUCKET,
  IDENTITY_POOL,
  REGION
} from './config';
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsExports = {
  aws_project_region: REGION,
  aws_cognito_region: REGION,
  aws_user_pools_id: USER_POOL_ID,
  aws_user_pools_web_client_id: USER_POOL_CLIENT_ID,
  aws_s3_bucket_name: S3_BUCKET,
  aws_identity_pool: IDENTITY_POOL,
  oauth: {},
  aws_cognito_login_mechanisms: ["PREFERRED_USERNAME"],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
};

export default awsExports;
