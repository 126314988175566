import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import {
  ConstructionForm, EquipmentForm, LogoContainer, MaintenanceForm, PrimaryContent, RenovationForm
} from '../../components';
import { useAppSelector } from '../../hooks';
import { selectLinkUser } from '../permission/permissionSlice';
import API from '../../api/api';

export const LinkUserPage = () => {
  const { t } = useTranslation();
  const [selectedForm, setSelectedForm] = useState<string>();
  const [propertyAddress, setPropertyAddress] = useState<string>();
  const linkUser = useAppSelector(selectLinkUser);

  useEffect(() => {
    const getProperty = async () => {
      if (linkUser) {
        const response: string = await API.get(`link/property/${linkUser.propertyId}/${linkUser.linkId}`);
        setPropertyAddress(response);
      }
    };
    getProperty();
  }, [linkUser]);

  return (
    <Box className="login-page setup-page">
      <LogoContainer showMenu={false} />
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="80%">
        <PrimaryContent
          title={propertyAddress || ''}
          button={{
            handleClick: () => setSelectedForm(undefined),
            text: selectedForm ? t('button.cancel') : '',
            icon: selectedForm ? <CloseOutlinedIcon /> : <span />
          }}
        >
          <>
            {!selectedForm && (
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="100%">
                <Button startIcon={<AddOutlinedIcon />} style={{ margin: '5px' }} variant="contained" onClick={() => setSelectedForm('maintenance')}>
                  {t('common.addNewMaintenance')}
                </Button>
                <Button startIcon={<AddOutlinedIcon />} style={{ margin: '5px' }} variant="contained" onClick={() => setSelectedForm('renovation')}>
                  {t('common.addNewRenovation')}
                </Button>
                <Button startIcon={<AddOutlinedIcon />} style={{ margin: '5px' }} variant="contained" onClick={() => setSelectedForm('equipment')}>
                  {t('common.addNewEquipment')}
                </Button>
                <Button startIcon={<AddOutlinedIcon />} style={{ margin: '5px' }} variant="contained" onClick={() => setSelectedForm('construction')}>
                  {t('common.addNewConstruction')}
                </Button>
              </Box>
            )}
            {selectedForm === 'maintenance' && (
              <MaintenanceForm edit={false} setEdit={() => {}} setAddNew={value => setSelectedForm(!value ? undefined : 'maintenance')} />
            )}
            {selectedForm === 'renovation' && (
              <RenovationForm handleClose={() => setSelectedForm(undefined)} />
            )}
            {selectedForm === 'equipment' && (
              <EquipmentForm handleClose={() => setSelectedForm(undefined)} />
            )}
            {selectedForm === 'construction' && (
              <ConstructionForm handleClose={() => setSelectedForm(undefined)} />
            )}
          </>
        </PrimaryContent>
      </Box>
    </Box>
  );
};
