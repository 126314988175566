import {
  Box, IconButton, Modal, Typography
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './ModalContainer.css';

interface ModalContainerProps {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  handleClose: () => void;
  title: string;
  subTitle?: string;
}

export const ModalContainer = ({
  title, subTitle, children, open, handleClose
}: ModalContainerProps) => (
  <Modal open={open} onClose={handleClose}>
    <Box className="modal-content">
      <Box className="header-container">
        <header className="title-container">
          <Typography component="h2" variant="h5">{title}</Typography>
          {subTitle && (<Typography component="p" variant="h6">{subTitle}</Typography>)}
        </header>
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <Box className="content-container">
        {children}
      </Box>
    </Box>
  </Modal>
);
