import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import s3Client from '../clients/s3Client';
import { S3_BUCKET } from '../../config';

const generatePreSignedUrl = async (key: string) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: key
  };

  try {
    const command = new GetObjectCommand(params);
    const url = await getSignedUrl(s3Client, command, { expiresIn: 10800 });
    return url;
  } catch (e) {
    return undefined;
  }
};

export default generatePreSignedUrl;
