import { Box } from '@mui/material';
import './NotificationContainer.css';

interface NotificationContainerProps {
  children: JSX.Element | JSX.Element[] | string;
  type: 'info' | 'success' | 'error';
}

export const NotificationContainer = ({ children, type }: NotificationContainerProps) => (
  <Box className={`notification-container ${type}`}>{children}</Box>
);
