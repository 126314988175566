import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography, Slide, Button
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addProperty } from '../property/propertySlice';
import { selectAccount } from '../../app/appSlice';
import { PropertyForm, LogoContainer } from '../../components';
import { Property } from '../../models/property';
import '@aws-amplify/ui-react/styles.css';
import './PropertySetupPage.css';

export const PropertySetupPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams();
  const [propertyFormData, setPropertyFormData] = useState<Partial<Property>>();

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);

  const isFirstSetup = type === 'aloitus';

  const title = isFirstSetup
    ? t('setup.welcomeTitle', { name: account?.name ? account.name.split(' ')[0] : '' })
    : t('setup.createANew');
  const confirmButtonText = isFirstSetup ? t('setup.saveAndContinue') : t('button.create');

  const createANewProperty = () => {
    if (!propertyFormData) {
      return;
    }
    dispatch(addProperty(propertyFormData as Property));
    navigate('/tilaus');
  };

  function containsLetters(propertyAddress: string | undefined) {
    if (!propertyAddress) {
      return false;
    }
    return /[a-zA-Z]/.test(propertyAddress);
  }

  return (
    <Box className="login-page setup-page">
      <LogoContainer showMenu={false} />
      <Slide direction="down" in>
        <Box className="authenticator-container setup-container">
          <header className="setup-header">
            <Typography component="h2" variant="h5">
              {title}
            </Typography>
            {isFirstSetup && <Typography>{t('setup.welcomeSubTitle')}</Typography>}
          </header>
          <Box className="content-container details-container">
            <PropertyForm
              propertyData={propertyFormData}
              isFirstSetup
              handleChange={
                (key: keyof Property, newValue: string | number | Date) => {
                  setPropertyFormData(prevState => ({ ...prevState, [key]: newValue }));
                }
              }
            />
          </Box>
          {/* TODO: UNDISABLED AFTER ALL FIELDS ARE READY */}
          <Button variant="contained" onClick={createANewProperty} disabled={!propertyFormData?.propertyCode || !containsLetters(propertyFormData?.propertyAddress)}>
            {confirmButtonText}
          </Button>
          {!isFirstSetup && (
            <Button onClick={() => navigate('/')}>{t('button.cancel')}</Button>
          )}
        </Box>
      </Slide>
    </Box>
  );
};
