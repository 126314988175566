import { ChangeLog, DocumentFile } from './common';

export interface Construction {
  constructionId: string;
  propertyId: string;
  startDate: string;
  workPart: ConstructionType;
  changeLog: ChangeLog[];
  documents: string[];
}

export interface ConstructionFormModel {
  startDate: string | null;
  workPart: ConstructionType | null;
  changeLog: ChangeLog[] | null;
  documents: DocumentFile[] | null;
}

export enum ConstructionType {
  LAND_WORK = 'landWork',
  FOUNDATION = 'foundation',
  FRAME = 'frame',
  ISOLATION = 'isolation',
  WATER_CEILING = 'waterCeiling',
  EXTERIOR_AND_TERRACE = 'exteriorAndTerrace',
  FLOOR_CASTING = 'floorCasting',
  WALLS = 'walls',
  TILING = 'tiling',
  OTHER_DECORATION = 'otherDecoration',
  YARD_WORK = 'yardWork',
  PLINTH_CASTING = 'plinthCasting'
}

export interface CreateConstruction {
  construction: ConstructionFormModel;
  propertyId?: string;
}

export interface UpdateConstruction {
  construction: Construction;
  propertyId: string;
  changeLog: ChangeLog | ChangeLog[] | null;
  documents?: DocumentFile[] | null;
}

export const requiredFields = ['workPart', 'startDate', 'changeLog'];
