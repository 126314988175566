import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  ConstructionForm,
  Image,
  ImageCarousel,
  ModalContainer,
  Pdf,
  PrimaryContent,
  SecondaryContent,
  Table,
  UpdateConstructionForm
} from '../../components';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import {
  fetchConstructionData, selectConstructions, selectConstructionLoading
} from './constructionSlice';
import { Document } from '../../models/common';
import { Construction, ConstructionType } from '../../models/construction';
import { selectSelectedProperty } from '../property/propertySlice';
import { dateToTableFormat } from '../../utils';
import { selectDocuments, selectDocumentsLoading } from '../document/documentSlice';
import './ConstructionPage.css';

export const ConstructionPage = () => {
  const { t } = useTranslation();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [expandImages, setExpandImages] = useState<string>();
  const [selectedConstruction, setSelectedConstruction] = useState<Construction>();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [category, setCategory] = useState<string>('all');

  const isMobile = useAppSelector(selectIsMobile);
  const constructionLoading = useAppSelector(selectConstructionLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const constructions = useAppSelector(selectConstructions);
  const docs = useAppSelector(selectDocuments);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchConstructionData(selectedProperty?.propertyId));
  }, []);

  useEffect(() => {
    setSelectedConstruction(undefined);
  }, [addNew]);

  const filteredConstructionData = useMemo(() => (category === 'all'
    ? constructions
    : constructions.filter(d => d.workPart === category)
  ), [category, constructions]);

  const getImages = (construction: Construction) => {
    const { documents } = construction;
    const images = docs.filter(({ type }) => type === 'image');
    return images ? images.filter(image => documents?.includes(image.documentId)) : [];
  };

  const showImages = (construction: Construction) => {
    if (category === 'all' || isMobile) {
      return expandImages === construction.constructionId;
    }

    return true;
  };

  return (
    <>
      <PrimaryContent
        title={t('common.construction')}
        subTitle={addNew ? t('construction.newWorkPart') : undefined}
        isLoading={constructionLoading || documentsLoading}
        button={isMobile
          ? {
            handleClick: () => setAddNew(!addNew),
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          } : {
            handleClick: () => setAddNew(!addNew),
            text: addNew ? t('button.cancel') : t('construction.new'),
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          }}
      >
        <Box className="content-container">
          {addNew ? (
            <ConstructionForm handleClose={() => setAddNew(false)} />
          ) : (
            <Box>
              <TextField
                label={t('construction.choose')}
                onChange={evt => setCategory(evt.target.value)}
                defaultValue="all"
                value={category}
                className="select-category"
                select
              >
                <MenuItem value="all">{t('common.all')}</MenuItem>
                {Object.values(ConstructionType).map(type => (
                  <MenuItem value={type} key={type}>{t(`construction.${type}`)}</MenuItem>
                ))}
              </TextField>
              {filteredConstructionData.length ? filteredConstructionData.map(construction => (
                <Box pb="20px" key={construction.constructionId}>
                  <header>
                    <Typography variant="h6" component="h3" fontWeight={construction.constructionId === selectedConstruction?.constructionId ? 'bold' : ''}>
                      {t(`construction.${construction.workPart}`)}
                      {' '}
                      <span style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                        {'('}{t('dateTime.startDate').toLowerCase()} {dateToTableFormat(construction.startDate)}{')'}
                      </span>
                    </Typography>
                  </header>
                  <Table tableHeaders={[t('dateTime.date'), t('common.note')]}>
                    {construction.changeLog?.map(log => (
                      <TableRow key={log.updatedAt}>
                        <TableCell>{dateToTableFormat(log.updatedAt || '')}</TableCell>
                        <TableCell>{log.description}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                  {category === 'all' && (
                  <Box>
                    {construction.documents?.length > 0 && (
                    <Button
                      className="basic-button"
                      endIcon={
                              expandImages === construction.constructionId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                            }
                      onClick={() => (
                        setExpandImages(expandImages === construction.constructionId
                          ? undefined : construction.constructionId)
                      )}
                    >
                      {t('common.images')}
                    </Button>
                    )}
                    {construction.constructionId !== selectedConstruction?.constructionId && (
                      <Button
                        className="basic-button"
                        onClick={() => setSelectedConstruction(construction)}
                      >
                        {t('construction.updateWorkPart')}
                      </Button>
                    )}
                  </Box>
                  )}
                  {category !== 'all' && (
                    <Button
                      className="basic-button"
                      onClick={() => setSelectedConstruction(construction)}
                    >
                      {t('construction.updateWorkPart')}
                    </Button>
                  )}
                  {showImages(construction) && (
                    <ImageCarousel images={getImages(construction)} handleSelectImage={setSelectedDocument} />
                  )}
                </Box>
              )) : <Typography>{t('construction.noConstructions')}</Typography>}
            </Box>
          )}
        </Box>
      </PrimaryContent>
      {!isMobile && selectedConstruction && (
        <SecondaryContent
          title={t(`construction.${selectedConstruction.workPart}`)}
          subTitle={t('construction.updateWorkPartInfo')}
        >
          <UpdateConstructionForm
            selectedConstruction={selectedConstruction}
            setSelectedConstruction={setSelectedConstruction}
          />
        </SecondaryContent>
      )}
      {isMobile && selectedConstruction && (
        <ModalContainer
          title={t(`construction.${selectedConstruction.workPart}`)}
          subTitle={t('construction.updateWorkPartInfo')}
          open={!!selectedConstruction}
          handleClose={() => setSelectedConstruction(undefined)}
        >
          <UpdateConstructionForm
            selectedConstruction={selectedConstruction}
            setSelectedConstruction={setSelectedConstruction}
          />
        </ModalContainer>
      )}
      {selectedDocument && (
        <ModalContainer
          title={selectedDocument.name}
          open={!!selectedDocument}
          handleClose={() => setSelectedDocument(undefined)}
        >
          {selectedDocument.type === 'pdf'
            ? (
              <Pdf
                s3Key={selectedDocument.s3Key}
                name={selectedDocument.name}
                description={selectedDocument.description}
              />
            )
            : (
              <Image
                name={selectedDocument.name}
                description={selectedDocument.description}
                s3Key={selectedDocument.s3Key}
                date={selectedDocument.date}
              />
            )}
        </ModalContainer>
      )}
    </>
  );
};
