import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import API from '../../api/api';
import { Permission } from '../../models/permission';

export const addLink = createAsyncThunk('permission/addLink', async ({ propertyId, permission, expirationDate }: Omit<Permission, 'linkId'>, thunkAPI) => {
  try {
    const link: Permission = await API.post('link', {
      propertyId,
      permission,
      expirationDate
    });
    return link;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const fetchLink = createAsyncThunk('permission/fetchLink', async (linkId: string, thunkAPI) => {
  try {
    const link: Permission = await API.get(`link/${linkId}`);
    return link;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const fetchLinks = createAsyncThunk('permission/fetchLinks', async (propertyId: string, thunkAPI) => {
  try {
    const link: Permission[] = await API.get(`links/${propertyId}`);
    return link;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

// Define a type for the slice state
interface PermissionState {
  loading: boolean;
  links: Permission[];
  linkUser?: Permission;
}

// Define the initial state using that type
const initialState: PermissionState = {
  loading: false,
  links: []
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addLink.pending, state => {
        state.loading = true;
      })
      .addCase(addLink.fulfilled, (state, action) => {
        state.loading = false;
        state.links.push(action.payload);
      })
      .addCase(addLink.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchLink.pending, state => {
        state.loading = true;
      })
      .addCase(fetchLink.fulfilled, (state, action) => {
        state.loading = false;
        state.linkUser = action.payload;
      })
      .addCase(fetchLink.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchLinks.pending, state => {
        state.loading = true;
      })
      .addCase(fetchLinks.fulfilled, (state, action) => {
        state.loading = false;
        state.links = action.payload;
      })
      .addCase(fetchLinks.rejected, state => {
        state.loading = false;
      });
  }
});

export const selectLoading = (state: RootState) => state.permission.loading;
export const selectLinks = (state: RootState) => state.permission.links;
export const selectLinkUser = (state: RootState) => state.permission.linkUser;

export default permissionSlice.reducer;
