import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';

import { AccountCircleOutlined } from '@mui/icons-material';
import { pages } from '../../houseData';
import './SideNavBar.css';
import { LogoContainer } from '../logoContainer/LogoContainer';
import { ProfileMenu } from '../profileMenu/ProfileMenu';
import { useAppSelector } from '../../hooks';
import { selectAccount } from '../../app/appSlice';
import { handleKeyDown } from '../../utils';

export const SideNavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const account = useAppSelector(selectAccount);

  const handleNavigation = (url: string) => {
    navigate(url);
  };

  const validateUser = (isAdmin?: boolean) => (!isAdmin || (isAdmin && account?.groups?.includes('admin')));

  return (
    <Box className="navigation-container">
      <LogoContainer showMenu />
      <List>
        {pages.map(page => validateUser(page.isAdmin) && (
          <ListItem
            key={page.label}
            className="list-item"
            role="link"
            tabIndex={0}
            onClick={() => handleNavigation(page.url)}
            onKeyDown={e => handleKeyDown(e.code, () => navigate(page.url))}
          >
            <Box className="item-text">
              <ListItemIcon>
                {page.icon}
              </ListItemIcon>
              <ListItemText>
                {t(`common.${page.label}`)}
              </ListItemText>
            </Box>
            {pathname === page.url && (
              <Fade in>
                <Box className="bold-line" />
              </Fade>
            )}
          </ListItem>
        ))}
      </List>
      <Box className="button-container">
        <Box display="flex" flexDirection="row">
          <AccountCircleOutlined style={{ marginRight: '5px' }} />
          <Typography sx={{ maxWidth: 200 }} noWrap>
            {account?.name || account?.email}
          </Typography>
        </Box>
        <ProfileMenu />
      </Box>
    </Box>
  );
};
