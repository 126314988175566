import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import {
  Table,
  PrimaryContent,
  SecondaryContent,
  MaintenanceForm,
  ModalContainer,
  Pdf,
  MobileTable,
  Image
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { Maintenance, MaintenanceType } from '../../models/maintenance';
import { Document } from '../../models/common';
import { dateToTableFormat, handleKeyDown } from '../../utils';
import {
  selectMaintenances,
  selectMaintenancesLoading,
  updateSelectedMaintenance
} from './maintenanceSlice';
import { selectDocuments, selectDocumentsLoading } from '../document/documentSlice';

export const MaintenancePage = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [category, setCategory] = useState<string>('all');
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useState<string>();
  const [selectedMaintenance, setSelectedMaintenance] = useState<Maintenance>();
  const today = new Date();

  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(selectIsMobile);
  const maintenances: Maintenance[] = useAppSelector(selectMaintenances)!;
  const docs = useAppSelector(selectDocuments);
  const maintenancesLoading = useAppSelector(selectMaintenancesLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);

  const filteredData = useMemo(() => (
    category === 'all' ? maintenances : maintenances.filter(d => d.maintenanceType === category)
  ), [category, maintenances]);

  const mobileData = filteredData.map(data => ({
    id: data.maintenanceId,
    column1: dateToTableFormat(data.maintenanceDate),
    column2: t(`maintenance.${data.maintenanceType}`)
  }));

  const upcomingMaintenances = [...maintenances]
    .sort((a, b) => (
      new Date(a.nextMaintenance).getTime() - new Date(b.nextMaintenance).getTime()
    ))
    .filter(d => new Date(d.nextMaintenance).getTime() >= today.getTime())
    .slice(0, 10).map(d => ({
      maintenanceType: t(`maintenance.${d.maintenanceType}`),
      nextMaintenance: dateToTableFormat(d.nextMaintenance)
    }));

  const isEditable = (maintenanceDate: string) => {
    const oneDayAhead = new Date(new Date(maintenanceDate).getTime() + 60 * 60 * 24 * 1000);
    return today < oneDayAhead;
  };

  const handleEditButton = (data: Maintenance) => {
    dispatch(updateSelectedMaintenance(data));
    setEdit(true);
    setAddNew(false);
  };

  const handleClick = () => {
    dispatch(updateSelectedMaintenance(undefined));
    setAddNew(!addNew);
  };

  const getSubTitle = () => {
    if (addNew) {
      return t('maintenance.new');
    }
    if (edit) {
      return t('button.edit');
    }
    return '';
  };

  const getButtonText = () => {
    if (edit) {
      return '';
    }
    if (addNew) {
      return t('button.cancel');
    }
    return t('button.new');
  };

  const mobileTableMaintenance = maintenances.find(maintenance => maintenance.maintenanceId === selectedMaintenanceId);

  return (
    <>
      <PrimaryContent
        title={t('common.maintenances')}
        subTitle={getSubTitle()}
        isLoading={maintenancesLoading || documentsLoading}
        button={isMobile
          ? {
            handleClick,
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          } : {
            handleClick,
            text: getButtonText(),
            icon: addNew ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          }}
      >
        {!addNew && !edit ? (
          <>
            <TextField
              className="select-category"
              label={t('maintenance.select')}
              onChange={evt => setCategory(evt.target.value)}
              defaultValue="all"
              select
            >
              <MenuItem value="all">{t('common.all')}</MenuItem>
              {Object.values(MaintenanceType).map(type => (
                <MenuItem key={type} value={type}>{t(`maintenance.${type}`)}</MenuItem>
              ))}
            </TextField>
            {isMobile && mobileData.length > 0 && (
            <MobileTable
              tableHeaders={[t('dateTime.date'), t('common.maintenance')]}
              data={mobileData}
              selected={selectedMaintenanceId}
              handleSelect={setSelectedMaintenanceId}
            >
              {mobileTableMaintenance && (
              <Box display="flex" justifyContent="space-between" margin="0 0 10px">
                <Box>
                  <dl>
                    <div>
                      <dt>{t('maintenance.nextMaintenance')}:</dt>
                      <dd>{dateToTableFormat(mobileTableMaintenance.nextMaintenance)}</dd>
                    </div>
                    <div>
                      <dt>{t('common.description')}:</dt>
                      <dd>{mobileTableMaintenance.description}</dd>
                    </div>
                    <div>
                      <dt>{t('common.documents')}:</dt>
                      <dd>
                        {docs.filter(doc => mobileTableMaintenance.documents?.includes(doc.documentId)).map(document => (
                          <Button key={document.documentId} className="underline-button" onClick={() => setSelectedDocument(document)}>
                            {document.name}
                          </Button>
                        ))}
                      </dd>
                    </div>
                  </dl>
                </Box>
                <Box>
                  <Button
                    className="basic-button"
                    onClick={() => handleEditButton(mobileTableMaintenance)}
                    disabled={!isEditable(mobileTableMaintenance.maintenanceDate)}
                  >
                    {t('button.edit')}
                  </Button>
                </Box>
              </Box>
              )}
            </MobileTable>
            )}
            {!isMobile && filteredData?.length > 0 && (
            <Table tableHeaders={[t('button.edit'), t('common.maintenance'), t('dateTime.date'), t('common.description'), t('maintenance.nextMaintenance'), '']}>
              {filteredData.map(maintenance => (
                <TableRow key={maintenance.maintenanceId}>
                  <TableCell>
                    <Tooltip title={isEditable(maintenance.maintenanceDate) ? t('button.edit') : t('maintenance.editDisabled')}>
                      <span>
                        <IconButton
                          className="icon-button"
                          onClick={() => handleEditButton(maintenance)}
                          disabled={!isEditable(maintenance.maintenanceDate)}
                        >
                          {isEditable(maintenance.maintenanceDate) ? <EditOutlinedIcon /> : <EditOffOutlinedIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{t(`maintenance.${maintenance.maintenanceType}`)}</TableCell>
                  <TableCell>{dateToTableFormat(maintenance.maintenanceDate)}</TableCell>
                  <TableCell>{maintenance.description}</TableCell>
                  <TableCell colSpan={maintenance.documents ? 1 : 2}>
                    {dateToTableFormat(maintenance.nextMaintenance)}
                  </TableCell>
                  {maintenance.documents && (
                  <TableCell>
                    <Button
                      className="underline-button"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setSelectedMaintenance(maintenance);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      {t('common.documents')}
                    </Button>
                  </TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
            )}
            {(!filteredData?.length || !mobileData?.length) && <Typography>{t('maintenance.noHistoryFound')}</Typography>}
          </>
        ) : (
          <MaintenanceForm
            setAddNew={setAddNew}
            edit={edit}
            setEdit={setEdit}
          />
        )}
      </PrimaryContent>
      {!isMobile && (
      <SecondaryContent title={t('maintenance.upcoming')}>
        {upcomingMaintenances?.length > 0 ? (
          <Table tableHeaders={[t('common.maintenance'), t('maintenance.nextMaintenance')]}>
            {upcomingMaintenances.map(maintenance => (
              <TableRow key={maintenance.maintenanceType + maintenance.nextMaintenance}>
                <TableCell>{maintenance.maintenanceType}</TableCell>
                <TableCell>{maintenance.nextMaintenance}</TableCell>
              </TableRow>
            ))}
          </Table>
        ) : <Typography>{t('maintenance.noUpcomingMaintenances')}</Typography>}
      </SecondaryContent>
      )}
      {selectedDocument && (
      <ModalContainer
        title={selectedDocument.name}
        open={!!selectedDocument}
        handleClose={() => setSelectedDocument(undefined)}
      >
        {selectedDocument.type === 'image' ? (
          <Image
            name={selectedDocument.name}
            description={selectedDocument.description}
            s3Key={selectedDocument.s3Key}
            date={selectedDocument.date}
          />
        ) : (
          <Pdf
            s3Key={selectedDocument.s3Key}
            name={selectedDocument.name}
            description={selectedDocument.description}
          />
        )}
      </ModalContainer>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box padding="5px">
          <Table tableHeaders={[t('common.name'), t('dateTime.date')]}>
            {docs.filter(doc => selectedMaintenance?.documents?.includes(doc.documentId)).map(document => (
              <TableRow
                key={document.documentId}
                onClick={() => setSelectedDocument(document)}
                onKeyDown={e => handleKeyDown(e.code, () => setSelectedDocument(document))}
                tabIndex={0}
                aria-label={t('button.open')}
                className="document-row"
              >
                <TableCell>{document.name}</TableCell>
                <TableCell>{dateToTableFormat(document.date)}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
      </Popover>
    </>
  );
};
