import { Tabs as MUITabs, Tab } from '@mui/material';
import './Tabs.css';
import { TabItem } from '../../models/components/tab';

interface TabsProps {
  items: TabItem[];
  selectedCategory: string;
  handleChange: (value: string) => void;
}

export const Tabs = ({ items, selectedCategory, handleChange }: TabsProps) => (
  <MUITabs
    className="tab-container"
    value={selectedCategory}
    onChange={(_, newValue) => handleChange(newValue)}
    variant="scrollable"
  >
    {items.map(item => <Tab className="tab" value={item.value} label={item.label} key={item.label} />)}
  </MUITabs>
);
