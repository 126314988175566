import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { NavigationLink } from './models/common';

export const pages: NavigationLink[] = [
  { label: 'property', url: '/', icon: <HouseOutlinedIcon className="navigation-icon" /> },
  { label: 'maintenances', url: '/huollot', icon: <EngineeringOutlinedIcon className="navigation-icon" /> },
  { label: 'renovations', url: '/remontit', icon: <FormatPaintOutlinedIcon className="navigation-icon" /> },
  { label: 'devicesAndMachines', url: '/koneet', icon: <CoffeeMakerOutlinedIcon className="navigation-icon" /> },
  { label: 'documents', url: '/dokumentit', icon: <FolderOpenOutlinedIcon className="navigation-icon" /> },
  { label: 'construction', url: '/rakentaminen', icon: <HandymanOutlinedIcon className="navigation-icon" /> },
  { label: 'brochure', url: '/esittely', icon: <ArticleOutlinedIcon className="navigation-icon" /> },
  { label: 'permissions', url: '/oikeudet', icon: <SecurityOutlinedIcon className="navigation-icon" /> },
  {
    label: 'users', url: '/kayttajat', isAdmin: true, icon: <GroupOutlinedIcon className="navigation-icon" />
  }
];
