import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Tabs } from '../tabs/Tabs';
import { TabItem } from '../../models/components/tab';
import { ChangeLog, DocumentFile } from '../../models/common';
import { Construction } from '../../models/construction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectConstructionLoading, updateConstruction } from '../../pages/construction/constructionSlice';
import { AddedDocuments } from './AddedDocuments';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { Loading } from '../loading/Loading';
import { selectDocumentsLoading } from '../../pages/document/documentSlice';

interface UpdateConstructionFormProps {
  selectedConstruction: Construction | undefined,
  setSelectedConstruction: Dispatch<SetStateAction<Construction | undefined>>
}

export const UpdateConstructionForm = ({ selectedConstruction, setSelectedConstruction }: UpdateConstructionFormProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>('note');
  const [newNote, setNewNote] = useState<ChangeLog>({
    updatedAt: null,
    description: null
  });
  const [newImages, setNewImages] = useState<DocumentFile[]>();

  const dispatch = useAppDispatch();
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const constructionLoading = useAppSelector(selectConstructionLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);

  const tabItems: TabItem[] = [
    { value: 'note', label: t('common.note') },
    { value: 'image', label: t('common.image') }
  ];

  const clearNote = () => {
    setNewNote({
      updatedAt: null,
      description: null
    });
  };

  useEffect(() => {
    if (selectedConstruction) {
      setNewNote({ updatedAt: new Date().toString(), description: '' });
    } else {
      setNewImages(undefined);
      clearNote();
    }
  }, [selectedConstruction]);

  const handleFileSelection = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString()
        });
      }
      setNewImages(newImages ? [...newImages, ...documents] : documents);
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (newImages) {
      const newDocuments = newImages.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setNewImages(newDocuments);
    }
  };

  const handleDelete = (document: DocumentFile) => {
    const documents = newImages?.filter(doc => doc.file?.name !== document.file?.name);
    setNewImages(documents);
  };

  const handleUpdateConstruction = async () => {
    if (!selectedConstruction || !selectedProperty) {
      return;
    }

    await dispatch(updateConstruction({
      construction: selectedConstruction,
      changeLog: newNote.updatedAt ? newNote : null,
      documents: newImages?.length ? newImages : null,
      propertyId: selectedProperty?.propertyId
    }));
    setSelectedConstruction(undefined);
  };

  const handleTabChange = (value: string) => {
    if (selectedConstruction && value === 'note') {
      setNewNote({ updatedAt: new Date().toString(), description: '' });
    }

    if (selectedConstruction && value === 'image') {
      clearNote();
    }
    setNewImages(undefined);
    setSelectedTab(value);
  };

  const isDisabled = () => !newNote.description?.length && !newImages;

  if (constructionLoading || documentsLoading) {
    return <Loading />;
  }

  return (
    <Box className="desktop-secondary-container">
      <Box>
        <Tabs
          items={tabItems}
          selectedCategory={selectedTab}
          handleChange={(value: string) => handleTabChange(value)}
        />
        {selectedTab === 'note' && (
          <TextField
            fullWidth
            label={newNote ? t('common.addNote') : t('common.addDescription')}
            type="text"
            className="form-field"
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => setNewNote({ ...newNote, description: target.value })}
            multiline
          />
        )}
        {selectedTab === 'image' && (
          <AddedDocuments
            acceptedFiles="image/*"
            documents={newImages || []}
            handleSelectFile={handleFileSelection}
            handleDescription={handleDescription}
            handleDelete={handleDelete}
          />
        )}
      </Box>
      <Box>
        <Button
          startIcon={<SaveOutlinedIcon />}
          className="basic-button"
          onClick={handleUpdateConstruction}
          disabled={isDisabled()}
        >
          {t('button.save')}
        </Button>
        <Button
          className="basic-button"
          onClick={() => setSelectedConstruction(undefined)}
        >
          {t('button.cancel')}
        </Button>
      </Box>
    </Box>
  );
};
