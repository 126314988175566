import {
  Box, List, ListItem, ListItemIcon, ListItemText, Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './OrderForm.css';
import { CheckCircleOutline } from '@mui/icons-material';

export const OrderForm = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center" mb="20px">
        <Typography component="p" variant="h2" fontWeight="bold">6 € / kk</Typography>
        <Typography>{t('order.billingInfo')}</Typography>
      </Box>
      <Box>
        <Typography component="h3" variant="h6">{t('common.features')}:</Typography>
        <List>
          {/* TODO: This list must be refined */}
          <ListItem disablePadding>
            <ListItemIcon>
              <CheckCircleOutline color="success" />
            </ListItemIcon>
            <ListItemText>
              Huollot
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <CheckCircleOutline color="success" />
            </ListItemIcon>
            <ListItemText>
              Remontit
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <CheckCircleOutline color="success" />
            </ListItemIcon>
            <ListItemText>
              Koneet
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <CheckCircleOutline color="success" />
            </ListItemIcon>
            <ListItemText>
              Dokumentit
            </ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon>
              <CheckCircleOutline color="success" />
            </ListItemIcon>
            <ListItemText>
              Taloesitteen luonti
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
