import {
  Box,
  TableCell,
  TableRow,
  Radio,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { selectIsMobile } from '../../app/appSlice';
import { useAppSelector } from '../../hooks';
import { dateToTableFormat } from '../../utils';
import { Table, MobileTable } from '../../components';
import { Renovation } from '../../models/renovation';

interface RenovationTableProps {
  renovations: Renovation[];
  selectedRenovation?: Renovation;
  setSelectedRenovation: (renovation: Renovation | undefined) => void;
}

export const RenovationTable = ({
  renovations, selectedRenovation, setSelectedRenovation
}: RenovationTableProps) => {
  const { t } = useTranslation();
  const isMobile = useAppSelector(selectIsMobile);
  const [selected, setSelected] = useState<Renovation>();

  const mobileTableData = renovations.map(renovation => ({
    id: renovation.renovationId,
    column1: dateToTableFormat(renovation.startDate),
    column2: renovation.renovation
  }));

  if (isMobile) {
    return (
      <MobileTable
        tableHeaders={[t('dateTime.started'), t('common.renovation')]}
        data={mobileTableData}
        selected={selected?.renovationId}
        handleSelect={(id: string | undefined) => setSelected(
          renovations.find(({ renovationId }) => renovationId === id)
        )}
      >
        <Box p="0 0 10px">
          <dl>
            <div>
              <dt>{t('renovation.constructor')}:</dt>
              <dd>{selectedRenovation?.constructor}</dd>
            </div>
            <div>
              <dt>{t('renovation.latest')}:</dt>
              <dd>{selected?.changeLog?.at(-1)?.description}</dd>
            </div>
          </dl>
          <Button
            className="underline-button"
            onClick={() => setSelectedRenovation(
              renovations.find(({ renovationId }) => renovationId === selected?.renovationId)
            )}
          >
            {t('common.changeLogAndDocuments')}
          </Button>
        </Box>
      </MobileTable>
    );
  }

  return (
    <Table
      tableHeaders={[
        t('common.choose'),
        t('common.renovation'),
        t('dateTime.startDate'),
        t('renovation.constructor'),
        t('renovation.latest')
      ]}
    >
      {renovations.map(renovation => (
        <TableRow
          key={renovation.renovationId}
          className="renovation-table-row"
          role="radio"
          aria-label="Select renovation to open the change log"
          onClick={() => setSelectedRenovation(renovation)}
        >
          <TableCell>
            <Radio
              onClick={() => setSelectedRenovation(renovation)}
              checked={renovation.renovationId === selectedRenovation?.renovationId}
            />
          </TableCell>
          <TableCell>{renovation.renovation}</TableCell>
          <TableCell>{dateToTableFormat(renovation.startDate)}</TableCell>
          <TableCell>{renovation.constructor}</TableCell>
          <TableCell>
            {renovation.changeLog ? renovation.changeLog[0].description : ''}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
