import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { BACKEND_URL } from '../config';

const API = axios.create({
  baseURL: BACKEND_URL
});

// set jwt for all request
API.interceptors.request.use(async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const jwtToken = (await fetchAuthSession()).tokens?.idToken?.toString();
  (config.headers as AxiosHeaders).set('Authorization', jwtToken);

  return config;
});

// Return data from response for every call
API.interceptors.response.use(
  response => response.data,
  error => {
    throw error;
  }
);

export default API;
