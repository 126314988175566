import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { isFormValid } from '../../utils';

interface FormButtonProps {
  formData: { [key: string]: any };
  requiredFields: Array<string>;
  handleSave: () => void;
}

export const FormButton = ({ formData, requiredFields, handleSave }: FormButtonProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Button
        startIcon={<SaveOutlinedIcon />}
        className="basic-button"
        onClick={handleSave}
        disabled={!isFormValid(formData, requiredFields)}
      >
        {t('button.save')}
      </Button>
      {!isFormValid(formData, requiredFields) && (
        <Typography variant="caption" fontStyle="italic">*{t('common.fillRequiredFields')}</Typography>
      )}
    </Box>
  );
};
