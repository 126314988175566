export interface Button {
  handleClick: () => void;
}

export interface TextButton extends Button {
  text: string;
  icon: JSX.Element;
}

export interface IconButton extends Button {
  icon: JSX.Element;
}

export const isTextButton = (button: IconButton | TextButton): button is TextButton => (button as TextButton).text !== undefined;
