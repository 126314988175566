export interface ChangeLog {
  updatedAt: string | undefined | null;
  description: string | undefined | null;
}

export interface Document {
  documentId: string;
  name: string;
  s3Key: string;
  type: 'pdf' | 'image';
  date: string;
  category?: string;
  description?: string;
}

export interface DocumentFile {
  file: File;
  description: string | null;
  category?: string;
  date: string;
}

export enum DocumentCategory {
  REPORTS = 'reports',
  CERTIFICATES = 'certificates',
  CONSTRUCTION = 'construction',
  MEASUREMENTS = 'measurements',
  OTHER = 'other'
}

export interface CreateDocument {
  document: DocumentFile;
  propertyId: string;
}

export interface Error {
  message: string;
  status?: number;
  data?: string;
}

export interface Account {
  groups?: string[];
  email?: string;
  name?: string;
}

export interface NavigationLink {
  label: string;
  url: string;
  isAdmin?: boolean;
  icon: JSX.Element;
}
