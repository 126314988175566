import { DefaultTexts } from '@aws-amplify/ui';

export const fi: Record<string, string> = {
  [DefaultTexts.BACK_SIGN_IN]: 'Siirry kirjautumiseen',
  [DefaultTexts.CODE]: 'Koodi',
  [DefaultTexts.NAME]: 'Nimi',
  [DefaultTexts.CODE_EMAILED]: 'Koodi lähetetty sähköpostiin',
  [DefaultTexts.CODE_SENT]: 'Koodi lähetetty',
  [DefaultTexts.CONFIRM]: 'Vahvista',
  [DefaultTexts.CONFIRMATION_CODE]: '',
  [DefaultTexts.CONFIRMING]: 'Vahvistetaan',
  [DefaultTexts.CONFIRM_PASSWORD]: 'Vahvista salasana',
  [DefaultTexts.CONFIRM_PASSWORD_PLACEHOLDER]: 'Salasana uudestaan',
  [DefaultTexts.CONFIRM_RESET_PASSWORD_HEADING]: 'Vahvista',
  [DefaultTexts.CONFIRM_SIGNUP_HEADING]: '',
  [DefaultTexts.CREATE_ACCOUNT]: 'Luo tili',
  [DefaultTexts.CREATING_ACCOUNT]: 'Tiliä luodaan',
  [DefaultTexts.EMAIL_ADDRESS]: 'Sähköpostiosoite',
  [DefaultTexts.ENTER_NAME]: 'Syötä nimesi',
  [DefaultTexts.ENTER_CODE]: 'Syötä koodi',
  [DefaultTexts.ENTER_CONFIRMATION_CODE]: '',
  [DefaultTexts.ENTER_EMAIL]: 'Syötä sähköpostiosoite',
  [DefaultTexts.ENTER_PASSWORD]: 'Syötä salasana',
  [DefaultTexts.FORGOT_PASSWORD]: 'Salasana unohtunut?',
  [DefaultTexts.FORGOT_YOUR_PASSWORD]: 'Unohditko salasanasi?',
  [DefaultTexts.HIDE_PASSWORD]: 'Piilota salasana',
  [DefaultTexts.LOADING]: 'Ladataan',
  [DefaultTexts.NEW_PASSWORD]: 'Uusi salasana',
  [DefaultTexts.OR]: 'tai',
  [DefaultTexts.PASSWORD]: 'Salasana',
  [DefaultTexts.RESEND_CODE]: 'Lähetä koodi uudelleen',
  [DefaultTexts.RESET_PASSWORD_HEADING]: 'dwkifjiwrg',
  [DefaultTexts.RESET_PASSWORD]: 'Palauta salasana',
  [DefaultTexts.SEND_CODE]: 'Lähetä',
  [DefaultTexts.SENDING]: 'Lähetetään',
  [DefaultTexts.SHOW_PASSWORD]: 'Näytä salasana',
  [DefaultTexts.SIGN_IN_BUTTON]: 'Kirjaudu',
  [DefaultTexts.SIGN_IN_TAB]: 'Kirjaudu sisään',
  [DefaultTexts.SIGN_IN]: 'Kirjaudu',
  [DefaultTexts.SIGN_UP_BUTTON]: 'Rekisteröidy',
  [DefaultTexts.SIGNING_IN_BUTTON]: 'Kirjaudutaan',
  [DefaultTexts.SKIP]: 'Ohita',
  [DefaultTexts.SUBMIT]: 'Lähetä',
  [DefaultTexts.SUBMITTING]: 'Lähetetään',
  [DefaultTexts.CODE_ARRIVAL]: 'Koodin saapuminen voi kestää jonkin aikaa',
  [DefaultTexts.WE_EMAILED]: 'Lähetimme sähköpostia',
  'Enter your email': 'Syötä sähköpostiosoitteesi'
};
