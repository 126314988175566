import { useEffect, useState } from 'react';
import {
  FormGroup,
  TextField,
  MenuItem,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddedDocuments } from './AddedDocuments';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addEquipment } from '../../pages/equipment/equipmentSlice';
import {
  ElecticaldApplianceTypes,
  EquipmentCategory,
  EquipmentFormModel,
  HouseholdApplianceTypes,
  SurfaceMaterials,
  WaterApplianceTypes,
  requiredFields
} from '../../models/equipment';
import { dateToFormFormat } from '../../utils';
import './styles.css';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { DocumentFile } from '../../models/common';
import { FormButton } from '../buttons/FormButton';

interface EquipmentFormProps {
  handleClose: () => void;
}

export const EquipmentForm = ({ handleClose }: EquipmentFormProps) => {
  const { t } = useTranslation();
  const [equipmentData, setEquipmentData] = useState<EquipmentFormModel>({
    name: null,
    category: null,
    purchaseDate: new Date().toString(),
    trademark: null,
    model: null,
    location: null,
    serialnumber: null,
    documents: null
  });
  const [selectedName, setSelectedName] = useState<string>();
  const [names, setNames] = useState<string[]>();

  const selectedProperty = useAppSelector(selectSelectedProperty);
  const dispatch = useAppDispatch();

  const types = {
    [EquipmentCategory.HOUSEHOLD_APPLIANCE_FIXED]: Object.values(HouseholdApplianceTypes),
    [EquipmentCategory.HOUSEHOLD_APPLIANCE_OWN]: Object.values(HouseholdApplianceTypes),
    [EquipmentCategory.ELECTRICAL_APPLIANCE]: Object.values(ElecticaldApplianceTypes),
    [EquipmentCategory.HVAC]: Object.values(WaterApplianceTypes),
    [EquipmentCategory.DECORATION_MATERIAL]: Object.values(SurfaceMaterials),
    [EquipmentCategory.OTHER]: null
  };

  useEffect(() => {
    if (equipmentData.category) {
      setNames(types[equipmentData.category] ?? undefined);
    }
  }, [equipmentData.category]);

  const handleSave = async () => {
    if (equipmentData) {
      await dispatch(addEquipment({ equipment: equipmentData, propertyId: selectedProperty?.propertyId }));
      handleClose();
    }
  };

  const handleChange = (key: string, value: string) => {
    let newValue: string | Date = value;
    if (key === 'purchaseDate') {
      newValue = new Date(`${value}T23:23:59`);
    }
    setEquipmentData({ ...equipmentData, [key]: newValue });
  };

  const handleFileSelection = (fileList: FileList | null) => {
    const { documents: stateDocuments } = equipmentData;
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString()
        });
      }
      setEquipmentData({
        ...equipmentData,
        documents: stateDocuments ? [...stateDocuments, ...documents] : documents
      });
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (equipmentData.documents) {
      const newDocuments = equipmentData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setEquipmentData({
        ...equipmentData,
        documents: newDocuments
      });
    }
  };

  const handleDelete = (document: DocumentFile) => {
    const documents = equipmentData.documents?.filter(doc => doc.file?.name !== document.file?.name);
    setEquipmentData({
      ...equipmentData,
      documents: documents || []
    });
  };

  return (
    <FormGroup className="form-container">
      <Box className="form-content-container">
        <TextField
          className="form-field"
          label={t('equipment.category')}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('category', target.value)}
          value={equipmentData.category || ''}
          select
          required
        >
          {Object.values(EquipmentCategory)
            .map(value => <MenuItem value={value} key={value}>{t(`equipment.${value}`)}</MenuItem>)}
        </TextField>
        {names ? (
          <TextField
            className="form-field"
            label={t('equipment.name')}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => setSelectedName(target.value)}
            onBlur={({ target }) => selectedName !== 'other' && handleChange('name', target.value)}
            value={selectedName || ''}
            select
            required
          >
            {names.map(name => <MenuItem value={name} key={name}>{t(`equipmentName.${name}`)}</MenuItem>)}
          </TextField>
        ) : (
          <TextField
            key="name"
            label={t('equipment.name')}
            type="text"
            className="form-field"
            InputLabelProps={{ shrink: true }}
            onBlur={({ target }) => handleChange('name', target.value)}
            required
          />
        )}
        <TextField
          className="form-field"
          label={t('equipment.location')}
          type="text"
          defaultValue={equipmentData.location || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('location', target.value)}
          required
        />
        <TextField
          className="form-field"
          label={t('equipment.purchased')}
          type="date"
          defaultValue={equipmentData && equipmentData.purchaseDate && dateToFormFormat(equipmentData.purchaseDate)}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('purchaseDate', target.value)}
          required
        />
        <TextField
          className="form-field"
          label={t('equipment.trademark')}
          type="text"
          defaultValue={equipmentData.trademark || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('trademark', target.value)}
        />
        <TextField
          className="form-field"
          label={t('equipment.model')}
          type="text"
          defaultValue={equipmentData.model || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('model', target.value)}
        />
        <TextField
          className="form-field"
          label={t('equipment.serialnumber')}
          type="text"
          defaultValue={equipmentData.serialnumber || ''}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('serialnumber', target.value)}
        />
        <AddedDocuments
          acceptedFiles="image/*,application/pdf"
          documents={equipmentData?.documents ?? []}
          handleSelectFile={handleFileSelection}
          handleDescription={handleDescription}
          handleDelete={handleDelete}
        />
      </Box>
      <FormButton handleSave={handleSave} formData={equipmentData} requiredFields={requiredFields} />
    </FormGroup>
  );
};
