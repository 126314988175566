import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import type { RootState } from '../../store';
import { User } from '../../models/user';

export const fetchUsers = createAsyncThunk('user/fetchUsers', async (_payload, thunkAPI) => {
  try {
    const response: User[] = await API.get('users');
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (email, thunkAPI) => {
  try {
    const response = await API.delete(`users/${email}`);
    thunkAPI.dispatch(fetchUsers());
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const addUser = createAsyncThunk('user/addUser', async (user, thunkAPI) => {
  try {
    const response: User = await API.post('users', user);
    thunkAPI.dispatch(fetchUsers());
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async (user: User, thunkAPI) => {
  const {
    admin,
    maintenance,
    owner,
    name,
    email,
    customer
  } : User = user;

  try {
    const response: User = await API.put(`users/${email}`, {
      admin,
      maintenance,
      owner,
      name,
      customer
    });
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

// Define a type for the slice state
interface UserState {
  users: User[];
  loading: boolean;
  error: string;
}

// Define the initial state using that type
const initialState: UserState = {
  users: [],
  loading: false,
  error: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteUser.pending, state => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: Return deleted user's email from the backend instead of this
        // @ts-ignore
        const email: string = action.meta.arg;
        state.users = state.users.filter(user => user.email !== email);
      })
      .addCase(deleteUser.rejected, state => {
        state.loading = false;
      })
      .addCase(addUser.pending, state => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.unshift(action.payload);
      })
      .addCase(addUser.rejected, state => {
        state.loading = false;
      })
      .addCase(updateUser.pending, state => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.map(user => {
          if (user.sub === action.payload.sub) {
            return { ...user, ...action.payload };
          }
          return user;
        });
      })
      .addCase(updateUser.rejected, state => {
        state.loading = false;
      });
  }
});

export const selectUsers = (state: RootState) => state.user.users;
export const selectLoading = (state: RootState) => state.user.loading;

export default userSlice.reducer;
