import { DeleteObjectsCommand } from '@aws-sdk/client-s3';
import { S3_BUCKET } from '../../config';
import s3Client from '../clients/s3Client';
import listS3Objects from './listS3Objects';

const deleteFromS3 = async (key: string) => {
  const objects = await listS3Objects(key);

  if (!key || !objects || objects.length === 0) {
    return undefined;
  }

  const params = {
    Bucket: S3_BUCKET,
    Delete: {
      Objects: objects.map(obj => ({ Key: obj }))
    }
  };

  try {
    const command = new DeleteObjectsCommand(params);
    const data = await s3Client.send(command);
    // Run again if over 1000 objects. AWS has limit for 1000 objects.
    if (data && objects?.length >= 1000) {
      await deleteFromS3(key);
    }
    return data;
  } catch (err) {
    return undefined;
  }
};

export default deleteFromS3;
