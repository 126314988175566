import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  TableCell,
  TableRow
} from '@mui/material';
import {
  PrimaryContent,
  Table
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchUsers, selectLoading, selectUsers } from './userSlice';

export const UsersPage = () => {
  const { t } = useTranslation();
  const users = useAppSelector(selectUsers);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <PrimaryContent title={t('common.users')} isLoading={loading}>
      <Box>
        <Table tableHeaders={[t('common.name'), t('users.email')]}>
          {users.map(user => (
            <TableRow className="renovation-table-row" key={user.name}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>
    </PrimaryContent>
  );
};
