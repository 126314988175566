import { useTranslation } from 'react-i18next';
import {
  Document,
  Image,
  Page,
  Text,
  View
} from '@react-pdf/renderer';
import { Property } from '../../models/property';
import { dateToTableFormat } from '../../utils';
import { styles } from './brochureDocumentStyle';

interface BrochureDocumentProps {
  property: Property;
  imageUrl?: string;
  renovations?: Record<number, { id: string, renovation: string }[]>;
}

export const BrochureDocument = (props: BrochureDocumentProps) => {
  const { property, imageUrl, renovations } = props;
  const { t } = useTranslation();
  const printableProperty = (({
    propertyId, userId, documents, ...o
  }) => o)(property);
  const propertyFeatures = Object.keys(printableProperty) as (keyof Omit<Property, 'propertyId' | 'userId' | 'documents'>)[];

  const getValue = (feature: keyof Omit<Property, 'propertyId' | 'userId' | 'documents'>) => {
    if (feature === 'deploymentTime') {
      return dateToTableFormat(property.deploymentTime);
    }
    if (feature === 'siteOwnership') {
      return t(`property.${printableProperty[feature]}`);
    }
    return printableProperty[feature];
  };

  if (!property) {
    return null;
  }

  return (
    <Document>
      <Page style={styles.page} wrap>
        <Text style={styles.title}>{printableProperty.propertyAddress.split(',').at(0)}</Text>
        {imageUrl && (
          <Image
            style={styles.image}
            src={imageUrl}
          />
        )}
        <View>
          {propertyFeatures.map(feature => (
            <View style={styles.row} key={feature}>
              <Text style={styles.label}>{t(`property.${feature}`)}</Text>
              <Text style={styles.value}>{getValue(feature)}</Text>
            </View>
          ))}
        </View>
        <View>{renovations && Object.keys(renovations).length > 0 && (
          <View style={styles.renovationContainer}>
            <Text style={styles.title}>{t('renovation.done')}</Text>
            {Object.entries(renovations!).map(([key, value]) => (
              <View style={styles.row} key={key}>
                <Text style={styles.label}>{key}</Text>
                <View style={styles.value}>
                  {value.map(v => <Text key={v.id}>{v.renovation}</Text>)}
                </View>
              </View>
            ))}
          </View>
        )}
        </View>
      </Page>
    </Document>
  );
};
