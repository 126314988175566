import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import './ConfirmContainer.css';

interface ConfirmProps {
  text: string;
  onCancel: () => void;
  confirmButton: {
    text: string;
    onConfirm: () => void;
  };
}

export const ConfirmContainer = ({ text, onCancel, confirmButton }: ConfirmProps) => {
  const { t } = useTranslation();
  return (
    <Box className="confirm-container">
      <Box className="confirm-content-container">
        <Typography variant="body1">{text}</Typography>
        <Box className="confirm-button-container">
          <Button className="basic-button" onClick={onCancel}>{t('button.cancel')}</Button>
          <Button className="basic-button" onClick={confirmButton.onConfirm}>{confirmButton.text}</Button>
        </Box>
      </Box>
    </Box>
  );
};
