import {
  Box, Button, IconButton as MUIIconButton, Typography
} from '@mui/material';
import { IconButton, TextButton, isTextButton } from '../../models/components/button';
import { Loading } from '../loading/Loading';
import './PageContent.css';

interface PrimaryContentProps {
  title: string;
  subTitle?: string;
  children: JSX.Element | JSX.Element[];
  button?: TextButton | IconButton;
  isLoading?: boolean;
}

export const PrimaryContent = ({
  title, subTitle, children, button, isLoading
}: PrimaryContentProps) => (
  <Box className="primary-content">
    <Box className="header-container">
      <header className="title-container">
        <Typography component="h2" variant="h5" className="primary-title">{title}</Typography>
        {subTitle && <Typography className="sub-title">{subTitle}</Typography>}
      </header>
      {button && !isTextButton(button) && (
        <MUIIconButton onClick={button.handleClick}>
          {button.icon}
        </MUIIconButton>
      )}
      {button && isTextButton(button) && (
        <Button
          startIcon={button.icon}
          onClick={button.handleClick}
          className="basic-button"
        >
          {button.text}
        </Button>
      )}
    </Box>
    <Box className="content-container">
      {isLoading ? <Loading /> : children}
    </Box>
  </Box>
);
