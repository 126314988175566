import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { AWS_SERVICE_IDENTITY_POOL_ID, REGION } from '../../config';

const s3Client = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: REGION },
    identityPoolId: AWS_SERVICE_IDENTITY_POOL_ID ?? ''
  })
});

export default s3Client;
