import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableCell,
  TableRow
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  ModalContainer,
  Pdf,
  PrimaryContent,
  Table,
  Tabs,
  NewDocument
} from '../../components';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';
import { selectSelectedProperty } from '../property/propertySlice';
import { selectDocuments, selectDocumentsLoading } from './documentSlice';
import { Document, DocumentCategory } from '../../models/common';
import { TabItem } from '../../models/components/tab';
import { dateToTableFormat, handleKeyDown } from '../../utils';
import '../../styles/common.css';

export const DocumentPage = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [addNewFile, setAddNewFile] = useState<boolean>(false);
  const isMobile = useAppSelector(selectIsMobile);
  const documents = useAppSelector(selectDocuments);
  const selectedProperty = useAppSelector(selectSelectedProperty);
  const loading = useAppSelector(selectDocumentsLoading);
  const categories: string[] = Object.values(DocumentCategory);

  const categoryItems: TabItem[] = ['all', ...categories]
    .map(category => ({ value: category, label: category === 'all' ? t('common.all') : t(`document.${category}`) }));

  const filteredDocuments = useMemo(() => (selectedCategory === 'all'
    ? documents.filter(({ category }) => category && categories.includes(category))
    : documents.filter(({ category }) => category === selectedCategory)
  ), [selectedCategory, documents]);

  return (
    <>
      <PrimaryContent
        title={t('common.documents')}
        isLoading={loading}
        button={isMobile
          ? {
            handleClick: () => setAddNewFile(!addNewFile),
            icon: addNewFile ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          } : {
            handleClick: () => setAddNewFile(!addNewFile),
            text: t(`button.${addNewFile ? 'cancel' : 'new'}`),
            icon: addNewFile ? <CloseOutlinedIcon /> : <AddOutlinedIcon />
          }}
      >
        {isMobile && addNewFile ? (
          <NewDocument
            selectedProperty={selectedProperty}
            setAddNewFile={setAddNewFile}
          />
        ) : (
          <>
            <Tabs
              items={categoryItems}
              selectedCategory={selectedCategory}
              handleChange={(value: string) => setSelectedCategory(value)}
            />
            {filteredDocuments.length > 0 ? (
              <Table tableHeaders={[t('common.name'), t('dateTime.date')]}>
                {filteredDocuments.map(document => (
                  <TableRow
                    key={document.documentId}
                    onClick={() => setSelectedDocument(document)}
                    onKeyDown={e => handleKeyDown(e.code, () => setSelectedDocument(document))}
                    tabIndex={0}
                    aria-label={t('button.open')}
                    className="document-row"
                  >
                    <TableCell>{document.name}</TableCell>
                    <TableCell>{dateToTableFormat(document.date)}</TableCell>
                  </TableRow>
                ))}
              </Table>
            ) : t('document.noDocuments')}
          </>
        )}
      </PrimaryContent>
      {!isMobile && addNewFile && (
        <NewDocument
          selectedProperty={selectedProperty}
          setAddNewFile={setAddNewFile}
        />
      )}
      {selectedDocument && (
        <ModalContainer
          title={selectedDocument.name}
          open={!!selectedDocument}
          handleClose={() => setSelectedDocument(undefined)}
        >
          <Pdf
            s3Key={selectedDocument.s3Key}
            name={selectedDocument.name}
            description={selectedDocument.description}
          />
        </ModalContainer>
      )}
    </>
  );
};
