import { PutObjectCommand } from '@aws-sdk/client-s3';
import s3Client from '../clients/s3Client';
import { S3_BUCKET } from '../../config';

const putObject = async (key: string, file: File) => {
  const input = {
    Body: file,
    Bucket: S3_BUCKET,
    Key: key
  };
  try {
    const command = new PutObjectCommand(input);
    const response = await s3Client.send(command);
    return response;
  } catch {
    return undefined;
  }
};

export default putObject;
