import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import app from './app/appSlice';
import construction from './pages/construction/constructionSlice';
import document from './pages/document/documentSlice';
import equipment from './pages/equipment/equipmentSlice';
import maintenance from './pages/maintenance/maintenanceSlice';
import property from './pages/property/propertySlice';
import order from './pages/orderPage/orderSlice';
import renovation from './pages/renovation/renovationSlice';
import user from './pages/users/userSlice';
import permission from './pages/permission/permissionSlice';
import profile from './pages/profile/profileSlice';

const appReducer = combineReducers({
  app,
  construction,
  document,
  equipment,
  maintenance,
  order,
  property,
  renovation,
  user,
  permission,
  profile
});

const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
