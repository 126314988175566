import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../api/api';
import type { RootState } from '../../store';
import { parseError } from '../../utils';
import { Subscription, UpdateNotify } from '../../models/subscription';
import { Property, UpdateProperty } from '../../models/property';
import { Error } from '../../models/common';

export const getSubscription = createAsyncThunk<Subscription, undefined, { rejectValue: Error }>(
  'payment/status',
  async (_, { rejectWithValue }) => {
    try {
      const response: Subscription = await API.get('payment/status');
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const cancelSubscription = createAsyncThunk<string, undefined, { rejectValue: Error }>(
  'payment/cancel',
  async (_, { rejectWithValue }) => {
    try {
      const response: string = await API.put('/payment/cancel');
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const changeSubChargeNotify = createAsyncThunk<Subscription, UpdateNotify, { rejectValue: Error }>(
  'payment/notify',
  async ({ notify }, { rejectWithValue }) => {
    try {
      const response: Subscription = await API.put('/payment/notify', {
        enabled: notify
      });
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

export const updateProperty = createAsyncThunk<Property, UpdateProperty, { rejectValue: Error }>(
  'property/updateProperty',
  async ({ property }, { rejectWithValue }) => {
    try {
      const response: Property = await API.put(`property/${property.propertyId}`, '1');
      return response;
    } catch (err: any) {
      const error = parseError(err);
      return rejectWithValue(error);
    }
  }
);

interface ProfileState {
  subscription: Subscription;
  loading: boolean;
  error: string;
}

const initialState: ProfileState = {
  subscription: {
    valid: '',
    active: false,
    notify: false
  },
  loading: false,
  error: ''
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
      })
      .addCase(getSubscription.rejected, state => {
        state.loading = false;
      })
      .addCase(cancelSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, state => {
        state.loading = false;
      })
      .addCase(cancelSubscription.rejected, state => {
        state.loading = false;
      })
      .addCase(changeSubChargeNotify.pending, state => {
        state.loading = true;
      })
      .addCase(changeSubChargeNotify.fulfilled, state => {
        state.loading = false;
      })
      .addCase(changeSubChargeNotify.rejected, state => {
        state.loading = false;
      });
  }
});

export const selectLoading = (state: RootState) => state.profile.loading;
export const selectSubscription = (state: RootState) => state.profile.subscription;

export default profileSlice.reducer;
