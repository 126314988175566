import { Box, Typography } from '@mui/material';
import './PageContent.css';

interface SecondaryContentProps {
  title: string;
  subTitle?: string;
  children: JSX.Element | JSX.Element[];
}

export const SecondaryContent = ({
  title, subTitle, children
}: SecondaryContentProps) => (
  <Box className="secondary-content">
    <Box className="secondary-header-container">
      <header>
        <Typography component="h2" variant="h5" className="secondary-title">{title}</Typography>
        {subTitle && (<Typography component="p" variant="h6" className="sub-title">{subTitle}</Typography>)}
      </header>
    </Box>
    <Box className="content-container">
      {children}
    </Box>
  </Box>
);
