import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Account, Error } from '../models/common';

// Define a type for the slice state
interface AppState {
  isMobile: boolean;
  error?: Error;
  account?: Account;
}

// Define the initial state using that type
const initialState: AppState = {
  isMobile: false,
  account: undefined
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
    updateIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    updateError: (state, action: PayloadAction<Error | undefined>) => {
      state.error = action.payload;
    }
  }
});

export const selectAccount = (state: RootState) => state.app.account;
export const selectIsMobile = (state: RootState) => state.app.isMobile;

export const { updateAccount, updateError, updateIsMobile } = appSlice.actions;
export default appSlice.reducer;
