import { useTranslation } from 'react-i18next';
import {
  Box, InputAdornment, MenuItem, TextField
} from '@mui/material';
import { Property, PlotOwnership, propertyKeys } from '../../models/property';
import { dateToFormFormat } from '../../utils';
import './styles.css';

interface PropertyFormProp {
  propertyData?: Partial<Property>;
  isFirstSetup?: boolean;
  handleChange: (key: keyof Property, newValue: string | number | Date) => void;
}

export const PropertyForm = ({ propertyData, isFirstSetup, handleChange }: PropertyFormProp) => {
  const { t } = useTranslation();

  const fields = propertyKeys.map(key => {
    const disabledText = !isFirstSetup && key === 'propertyCode' && propertyData && !!propertyData.propertyCode;
    const disabledNumber = !isFirstSetup && key === 'buildingYear' && propertyData && !!propertyData.buildingYear;

    switch (key) {
      case 'propertyAddress':
      case 'propertyZipCode':
      case 'propertyCity': {
        let index = 0;
        if (key === 'propertyZipCode') {
          index = 1;
        }
        if (key === 'propertyCity') {
          index = 2;
        }
        const valueArr = propertyData?.propertyAddress ? propertyData.propertyAddress.split(',') : ['', '', ''];

        return (
          <TextField
            key={key}
            label={t(`property.${key}`)}
            type="text"
            className="form-field"
            defaultValue={valueArr[index]?.trim()}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => {
              valueArr[index] = target.value;
              handleChange('propertyAddress', valueArr.join(', '));
            }}
            disabled={disabledText}
          />
        );
      }
      case 'propertyCode':
      case 'siteType':
        return (
          <TextField
            key={key}
            label={t(`property.${key}`)}
            type="text"
            className="form-field"
            defaultValue={propertyData && propertyData[key]}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => handleChange(key, target.value)}
            disabled={disabledText}
          />
        );
      case 'buildingRight':
      case 'buildingRightLeft':
      case 'buildingYear':
      case 'siteSize':
        return (
          <TextField
            key={key}
            label={t(`property.${key}`)}
            type="number"
            className="form-field"
            defaultValue={propertyData && propertyData[key]}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => handleChange(key, target.value)}
            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
            disabled={disabledNumber}
            InputProps={{
              endAdornment: key !== 'buildingYear' && <InputAdornment position="end">m<sup>2</sup></InputAdornment>
            }}
          />
        );
      case 'siteOwnership':
        return (
          <TextField
            key={key}
            label={t('property.siteOwnership')}
            className="form-field"
            value={propertyData?.siteOwnership ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => handleChange(key, target.value)}
            select
          >
            {Object.values(PlotOwnership).map(value => <MenuItem key={value} value={value}>{t(`property.${value}`)}</MenuItem>)}
          </TextField>
        );
      case 'plotLeasingCost':
        if (propertyData?.siteOwnership === PlotOwnership.RENTAL) {
          return (
            <TextField
              key={key}
              label={t('property.plotLeasingCost')}
              type="number"
              className="form-field"
              defaultValue={propertyData.plotLeasingCost ?? ''}
              InputLabelProps={{ shrink: true }}
              onChange={({ target }) => handleChange(key, target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{`€ / ${t('dateTime.year')}`}</InputAdornment>
              }}
            />
          );
        }
        return undefined;
      case 'deploymentTime':
        return (
          <TextField
            key={key}
            label={t('property.deploymentTime')}
            type="date"
            className="form-field"
            defaultValue={propertyData?.deploymentTime ? dateToFormFormat(propertyData.deploymentTime) : ''}
            InputLabelProps={{ shrink: true }}
            onChange={({ target }) => handleChange(key, new Date(`${target.value}T00:00:00`))}
            disabled={!isFirstSetup && propertyData && !!propertyData.deploymentTime}
          />
        );
      default:
        return undefined;
    }
  });

  return (
    <Box className="form-container">
      <Box className="form-content-container">
        {fields}
      </Box>
    </Box>
  );
};
