import { signOut } from 'aws-amplify/auth';
import { useAppDispatch } from '.';

export const useLogOut = () => {
  const dispatch = useAppDispatch();

  const logOut = async () => {
    await signOut();
    localStorage.removeItem('selectedProperty');
    localStorage.removeItem('account');
    await new Promise(r => { setTimeout(r, 1000); });
    dispatch({ type: 'USER_LOGOUT' });
  };

  return logOut;
};
