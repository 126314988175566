import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  TextField,
  MenuItem,
  Box
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addConstruction, selectConstructions, updateConstruction } from '../../pages/construction/constructionSlice';
import { AddedDocuments } from './AddedDocuments';
import {
  Construction, ConstructionFormModel, ConstructionType, requiredFields
} from '../../models/construction';
import { ChangeLog, DocumentFile } from '../../models/common';
import { dateToFormFormat } from '../../utils';
import './styles.css';
import { selectSelectedProperty } from '../../pages/property/propertySlice';
import { FormButton } from '../buttons/FormButton';

interface ConstructionFormProps {
  handleClose: () => void
}

export const ConstructionForm = ({ handleClose }: ConstructionFormProps) => {
  const { t } = useTranslation();
  const [constructionData, setConstructionData] = useState<ConstructionFormModel>({
    workPart: null,
    startDate: new Date().toString(),
    changeLog: null,
    documents: null
  });
  const dispatch = useAppDispatch();
  const savedConstructions = useAppSelector(selectConstructions);
  const selectedProperty = useAppSelector(selectSelectedProperty);

  const handleSave = async () => {
    if (!constructionData) {
      return;
    }

    const savedConstruction = savedConstructions.find(({ workPart }) => workPart === constructionData.workPart);

    if (savedConstruction && selectedProperty?.propertyId) {
      await dispatch(updateConstruction({
        construction: savedConstruction,
        documents: constructionData.documents,
        changeLog: constructionData?.changeLog,
        propertyId: selectedProperty.propertyId
      }));
      handleClose();
      return;
    }

    await dispatch(addConstruction({
      construction: constructionData,
      propertyId: selectedProperty?.propertyId
    }));

    handleClose();
  };

  const handleChange = (key: keyof Construction, value: string) => {
    let newValue: string | ChangeLog[] | Date = value;
    if (key === 'changeLog') {
      // @ts-ignore
      newValue = value ? [{ updatedAt: new Date().toString(), description: value }] : null;
    }

    if (key === 'startDate') {
      // @ts-ignore
      newValue = value ? new Date(value) : null;
    }
    setConstructionData({ ...constructionData, [key]: newValue });
  };

  const handleFileSelection = (fileList: FileList | null) => {
    const { documents: stateDocuments } = constructionData;
    if (fileList && fileList.length > 0) {
      const documents: DocumentFile[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        documents.push({
          file: fileList[i],
          description: null,
          date: new Date().toString()
        });
      }
      setConstructionData({
        ...constructionData,
        documents: stateDocuments ? [...stateDocuments, ...documents] : documents
      });
    }
  };

  const handleDescription = (value: string, file: File | null) => {
    if (constructionData.documents) {
      const newDocuments = constructionData.documents.map(document => (
        document.file?.name === file?.name ? { ...document, description: value } : document
      ));
      setConstructionData({
        ...constructionData,
        documents: newDocuments
      });
    }
  };

  const handleDelete = (document: DocumentFile) => {
    const documents = constructionData.documents?.filter(doc => doc.file?.name !== document.file?.name);
    setConstructionData({
      ...constructionData,
      documents: documents || []
    });
  };

  return (
    <FormGroup className="form-container">
      <Box className="form-content-container">
        <TextField
          className="form-field"
          label={t('construction.workPart')}
          InputLabelProps={{ shrink: true }}
          onChange={({ target }) => handleChange('workPart', target.value)}
          value={constructionData.workPart || ''}
          select
          required
        >
          {Object.values(ConstructionType)
            .map(value => <MenuItem value={value} key={value}>{t(`construction.${value}`)}</MenuItem>)}
        </TextField>
        <TextField
          label={t('dateTime.startDate')}
          type="date"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          value={dateToFormFormat(constructionData.startDate || '')}
          onChange={({ target }) => handleChange('startDate', target.value)}
          required
        />
        <TextField
          label={t('common.note')}
          type="text"
          className="form-field"
          InputLabelProps={{ shrink: true }}
          defaultValue={constructionData.changeLog || ''}
          onChange={({ target }) => handleChange('changeLog', target.value)}
          multiline
          required
        />
        <AddedDocuments
          acceptedFiles="image/*"
          documents={constructionData?.documents ?? []}
          handleSelectFile={handleFileSelection}
          handleDescription={handleDescription}
          handleDelete={handleDelete}
        />
      </Box>
      <FormButton handleSave={handleSave} formData={constructionData} requiredFields={requiredFields} />
    </FormGroup>
  );
};
