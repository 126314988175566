import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import { Document } from '../../models/common';
import './style.css';
import { dateToTableFormat } from '../../utils';
import generatePreSignedUrl from '../../aws/api/generatePreSignedUrl';

interface ImageCarouselProps {
  image: Document;
  handleSelectImage: (document: Document) => void;
}

export const ImageCard = ({ image, handleSelectImage }: ImageCarouselProps) => {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const fetchUrl = async () => {
      setUrl(await generatePreSignedUrl(image.s3Key));
    };

    fetchUrl();
  }, [image.s3Key]);

  return (
    <Card className="image-card-container" onClick={() => handleSelectImage(image)}>
      <CardMedia
        className="card-image"
        image={url}
        title={image.name}
        component="img"
      />
      <CardContent className="image-text-container">
        <Typography variant="body2" fontWeight="bold" className="image-text">
          {image.name}
        </Typography>
        <Typography variant="body2" className="image-text">
          {dateToTableFormat(image.date)}
        </Typography>
      </CardContent>
    </Card>
  );
};
