import {
  Table as MUITable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './MobileTable.css';
import { Fragment } from 'react';

interface MobileTableData {
  id: string;
  column1: string;
  column2: string;
}

interface MobileTableProps {
  tableHeaders: string[];
  data: MobileTableData[];
  children?: JSX.Element | JSX.Element[];
  selected?: string;
  handleSelect: (id: string | undefined) => void
}

export const MobileTable = ({
  tableHeaders, data, children, selected, handleSelect
}: MobileTableProps) => (
  <TableContainer className="mobile-table-container">
    <MUITable size="small">
      <TableHead>
        <TableRow>
          {tableHeaders.map(header => <TableCell key={header}>{header}</TableCell>)}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <Fragment key={row.id}>
            <TableRow
              className="mobile-table-row"
              role="button"
              onClick={() => handleSelect(selected !== row.id ? row.id : undefined)}
            >
              <TableCell>{row.column1}</TableCell>
              <TableCell>{row.column2}</TableCell>
              <TableCell className="collapse-icon">
                {selected === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <Collapse in={selected === row.id} timeout="auto" unmountOnExit>
                  {children}
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </MUITable>
  </TableContainer>
);
